import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ColorPicker from "hooks/ColorPicker";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormConfiguration extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    configuration: PropTypes.object,

    createConfiguration: PropTypes.func,
    updateConfiguration: PropTypes.func,
    deleteConfiguration: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      loading: false,
      label: "",
      organizationID: "",
      code: "",
      templateName: "",
      sentFromName: "",
      sentFromEmail: "",
      primaryColor: "",
      secondaryColor: "",
      appLogo: "",
      backgroundColor: "",
      fontBundleID: "",
      fontFamily: "",
      footer: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.configuration);
    }
  }

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          const link = r.payload;
          this.setState({ appLogo: link });
        });
      }
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  init(configuration) {
    this.setState({
      loading: false,
      label: configuration ? configuration.label : "",
      organizationID: configuration ? configuration.organizationID : "",
      code: configuration ? configuration.code : "",
      templateName: configuration ? configuration.templateName : "",
      sentFromName: configuration ? configuration.sentFromName : "",
      sentFromEmail: configuration ? configuration.sentFromEmail : "",
      appLogo: configuration ? configuration.appLogo : "",
      primaryColor: configuration ? configuration.primaryColor : "",
      secondaryColor: configuration ? configuration.secondaryColor : "",
      backgroundColor: configuration ? configuration.backgroundColor : "",
      fontBundleID: configuration ? configuration.fontBundleID : "",
      fontFamily: configuration ? configuration.fontFamily : "",
      footer: configuration ? configuration.footer : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this configuration, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteConfiguration, close, refresh, configuration } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteConfiguration(configuration.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Configuration has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateConfiguration,
      createConfiguration,
      configuration,
      refresh,
      close,
    } = this.props;

    const {
      label,
      organizationID,
      code,
      templateName,
      sentFromName,
      sentFromEmail,
      appLogo,
      primaryColor,
      secondaryColor,
      backgroundColor,
      fontBundleID,
      fontFamily,
      footer,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (configuration) {
      resp = await updateConfiguration(configuration.id, {
        label,
        organizationID: Number(organizationID),
        code,
        templateName,
        sentFromName,
        sentFromEmail,
        primaryColor,
        secondaryColor,
        appLogo,
        backgroundColor,
        fontBundleID,
        fontFamily,
        footer,
      });
    } else {
      resp = await createConfiguration({
        label,
        organizationID: Number(organizationID),
        code,
        templateName,
        sentFromName,
        sentFromEmail,
        primaryColor,
        secondaryColor,
        appLogo,
        backgroundColor,
        fontBundleID,
        fontFamily,
        footer,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: configuration
          ? "Configuration has been updated."
          : "Configuration has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, configuration, close } = this.props;

    const {
      loading,
      label,
      organizationID,
      code,
      templateName,
      sentFromName,
      sentFromEmail,
      appLogo,
      backgroundColor,
      primaryColor,
      secondaryColor,
      fontBundleID,
      fontFamily,
      footer,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {configuration
                ? `${configuration.label}`
                : "Let's Create a New Configuration"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {configuration
                  ? "To update the configuration, fill the form below :"
                  : "To create a new configuration, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="label"
                    label="Label"
                    className={classes.textField}
                    value={label}
                    onChange={this.handleChange("label")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="organizationID"
                    label="Organization ID"
                    className={classes.textField}
                    value={organizationID}
                    onChange={this.handleChange("organizationID")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="code"
                    label="Code"
                    className={classes.textField}
                    value={code}
                    onChange={this.handleChange("code")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="templateName"
                    label="Template Name"
                    className={classes.textField}
                    value={templateName}
                    onChange={this.handleChange("templateName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="sentFromName"
                    label="Sent From Name"
                    className={classes.textField}
                    value={sentFromName}
                    onChange={this.handleChange("sentFromName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="sentFromEmail"
                    label="Sent From Email"
                    className={classes.textField}
                    value={sentFromEmail}
                    onChange={this.handleChange("sentFromEmail")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="appLogo"
                    multiline
                    label="App Logo"
                    className={classes.textField}
                    value={appLogo}
                    onChange={this.handleChange("appLogo")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Background Color
                  </Typography>
                  <ColorPicker
                    onChange={(c) => {
                      this.setState({ backgroundColor: c });
                    }}
                    color={backgroundColor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Primary Color
                  </Typography>
                  <ColorPicker
                    onChange={(c) => {
                      this.setState({ primaryColor: c });
                    }}
                    color={primaryColor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    Secondary Color
                  </Typography>
                  <ColorPicker
                    onChange={(c) => {
                      this.setState({ secondaryColor: c });
                    }}
                    color={secondaryColor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="fontBundleID"
                    label="Adobe Font Bundle ID"
                    className={classes.textField}
                    value={fontBundleID}
                    onChange={this.handleChange("fontBundleID")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="fontFamily"
                    label="Font Family"
                    className={classes.textField}
                    value={fontFamily}
                    onChange={this.handleChange("fontFamily")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="footer"
                    variant="outlined"
                    multiline
                    label="Footer"
                    className={classes.textField}
                    value={footer}
                    onChange={this.handleChange("footer")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {configuration ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />{" "}
                {configuration ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormConfiguration));
