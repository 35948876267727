import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// components
import CouriersList from "components/CouriersList";
import Page from "components/Page";
import FormCourier from "components/FormCourier";

// constant
import { COURIER } from "constants/routes";

// styles
import styles from "./styles";

class WrapperCouriersPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //couriers
    couriers: PropTypes.array,
    getCourierById: PropTypes.func,
    createCourier: PropTypes.func,
    deleteCourier: PropTypes.func,
    updateCourier: PropTypes.func,

    tags: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCourier: undefined,
      formCourierOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { couriers, history, tags, getCouriers } = this.props;

    if (index === tags.length) {
      return (
        <CouriersList
          couriers={couriers.filter((c) => c.archived)}
          tags={tags}
          history={history}
          getCouriers={getCouriers}
          onEdit={(c) =>
            this.setState({
              selectedCourier: c,
              formCourierOpen: true,
            })
          }
          onSelect={(selectedCourier) =>
            history.push(`${COURIER}?id=${selectedCourier.id}`)
          }
        />
      );
    }

    return (
      <CouriersList
        couriers={couriers.filter(
          (c) => c.tagID === tags[index].id && !c.archived
        )}
        tags={tags}
        history={history}
        getCouriers={getCouriers}
        onEdit={(c) =>
          this.setState({
            selectedCourier: c,
            formCourierOpen: true,
          })
        }
        onSelect={(selectedCourier) =>
          history.push(`${COURIER}?id=${selectedCourier.id}`)
        }
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      tags,
      createCourier,
      deleteCourier,
      updateCourier,
      refresh,
    } = this.props;

    const { index, selectedCourier, formCourierOpen } = this.state;

    return (
      <Page
        helmet={"Couriers"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formCourierOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            {tags &&
              tags.map((t) => (
                <Tab
                  label={t.name}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  style={{
                    color: t.color,
                  }}
                />
              ))}
            <Tab
              label="Archived"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormCourier
          open={formCourierOpen}
          courier={selectedCourier}
          close={() => {
            this.setState({
              formCourierOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCourier: undefined,
                }),
              200
            );
          }}
          createCourier={createCourier}
          deleteCourier={deleteCourier}
          updateCourier={updateCourier}
          tags={tags}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCouriersPage);
