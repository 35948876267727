import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import { FormEventReason, FormRule, FormSubscription } from "components";
import EventReasonsList from "components/EventReasonsList";
import Page from "components/Page";
import RulesList from "components/RulesList";
// components
import SubscriptionsList from "components/SubscriptionsList";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperSubscriptionsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //subscriptions
    api: PropTypes.object,

    subscriptions: PropTypes.array,
    rules: PropTypes.array,
    eventReasons: PropTypes.array,
    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSubscription: undefined,
      formSubscriptionOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      subscriptions,
      rules,
      eventReasons,
      history,
      api,
      searchUsers,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <RulesList
            rules={rules}
            history={history}
            onSelect={(selectedRule) =>
              this.setState({
                selectedRule,
                formRuleOpen: true,
              })
            }
          />
        );
      case 1:
        return (
          <EventReasonsList
            eventReasons={eventReasons}
            history={history}
            onSelect={(selectedEventReason) =>
              this.setState({
                selectedEventReason,
                formEventReasonOpen: true,
              })
            }
          />
        );
      case 2:
        return (
          <SubscriptionsList
            subscriptions={subscriptions}
            searchUsers={searchUsers}
            getSubscriptions={api.getSubscriptions}
            history={history}
            onSelect={(e) =>
              this.setState({
                selectedSubscription: e,
                formSubscriptionOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      api,
      refresh,
      eventReasons,
    } = this.props;

    const { index, formSubscriptionOpen, selectedSubscription } = this.state;

    return (
      <Page
        helmet={"Subscriptions"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            style={{ display: index === 2 ? "none" : undefined }}
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => {
              switch (index) {
                case 0:
                  this.setState({ formRuleOpen: true });
                  break;
                case 1:
                  this.setState({ formEventReasonOpen: true });
                  break;
              }
            }}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Rules"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Reasons"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Subscriptions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormRule
          open={this.state.formRuleOpen}
          rule={this.state.selectedRule}
          close={() => {
            this.setState({
              formRuleOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedRule: undefined,
                }),
              200
            );
          }}
          createRule={api.createRule}
          deleteRule={api.deleteRule}
          updateRule={api.updateRule}
          refresh={refresh}
        />
        <FormEventReason
          open={this.state.formEventReasonOpen}
          eventReason={this.state.selectedEventReason}
          close={() => {
            this.setState({
              formEventReasonOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedEventReason: undefined,
                }),
              200
            );
          }}
          createEventReason={api.createEventReason}
          deleteEventReason={api.deleteEventReason}
          updateEventReason={api.updateEventReason}
          refresh={refresh}
        />
        <FormSubscription
          open={this.state.formSubscriptionOpen}
          eventReasons={eventReasons}
          subscription={this.state.selectedSubscription}
          rules={this.props.rules}
          close={() => {
            this.setState({
              formSubscriptionOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSubscription: undefined,
                }),
              200
            );
          }}
          api={api}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSubscriptionsPage);
