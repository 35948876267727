import { networkAction } from "helpers/network/networkAction";

import {
  GET_TRAILS,
  GET_TRAIL_BY_ID,
  DELETE_TRAIL,
  UPDATE_TRAIL,
  CREATE_TRAIL,
} from "constants/trails";

import * as Api from "api";

export const getTrails = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_TRAILS, Api.getTrails, [filters]);

export const getTrailById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TRAIL_BY_ID, Api.getTrailById, [id]);

export const deleteTrail = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TRAIL, Api.deleteTrail, [id]);

export const createTrail = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TRAIL, Api.createTrail, [body]);

export const updateTrail = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TRAIL, Api.updateTrail, [id, body]);
