import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

// components
import CourierItems from "components/CourierItems";

// styles
import styles from "./styles";

class List extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
    list: PropTypes.object,
    api: PropTypes.object,
    refresh: PropTypes.func,
    listIndex: PropTypes.number,
  };

  render() {
    const { list, selected, onSelect, api, refresh } = this.props;

    const s =
      list.id === selected.courierListID &&
      selected.courierItemID === undefined;

    return (
      <div
        style={{
          background: !s ? "rgba(155,155,155,0.1)" : "rgba(33,150,243, 0.1)",
          border: !s
            ? "solid 1px rgba(155,155,155,0.3)"
            : "solid 1px rgba(33,150,243, 0.3)",
          padding: 10,
          margin: 16,
          borderRadius: 4,
          boxShadow:
            "0 3px 3px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(list.id, undefined);
        }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <DragIndicatorIcon
              id="grab"
              style={{
                fontSize: 12,
                color: "rgba(155,155,155)",
                cursor: "grab",
              }}
            />
            <Typography color="textSecondary" style={{ fontSize: 8 }}>
              {list.id}
            </Typography>
          </Grid>
          {list.label ? (
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  color: "rgba(155,155,155, 1)",
                  border: "solid 1px rgba(155,155,155, 1)",
                  borderRadius: 25,
                  padding: "2px 6px",
                }}
              >
                {list.label}
              </Typography>
            </Grid>
          ) : (
            []
          )}
          <Grid
            item
            xs={list.label ? 12 : undefined}
            style={{ flexGrow: 1, padding: 12 }}
          >
            <CourierItems
              courierItems={list.items}
              courierListID={list.id}
              refresh={refresh}
              api={api}
              parentID={list.id}
              onSelect={onSelect}
              selected={selected}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(List);
