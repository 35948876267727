import React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PaletteIcon from "@material-ui/icons/Palette";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Button from "@material-ui/core/Button";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import styles from "./styles";
import ColorPicker from "../ColorPicker/ColorPicker";

const useStyles = makeStyles(styles);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(155,155,155,0.3)",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function Customizer({
  selected,
  api,
  NotificationCenter,
  saved,
}) {
  const { courierListID, courierItemID } = selected;
  const [loading, setLoading] = React.useState(true);
  const [item, setItem] = React.useState();
  const [type, setType] = React.useState("");

  React.useEffect(() => {
    if (loading === true) {
      if (courierItemID) {
        api.getCourierItemById(courierItemID).then((r) => {
          setLoading(false);
          setItem(r.payload);
          setType("Item");
        });
      } else if (courierListID) {
        api.getCourierListById(courierListID).then((r) => {
          setLoading(false);
          setItem(r.payload);
          setType("List");
        });
      }
    }
  });

  if (loading || item === undefined) {
    return <CircularProgress />;
  }

  return (
    <CustomizerUI
      item={item}
      api={api}
      type={type}
      NotificationCenter={NotificationCenter}
      saved={saved}
    />
  );
}

function getBorderSpecs(border) {
  if (border && border.length) {
    const split = border.split(" ");
    if (split.length > 2) {
      return {
        _borderType: split[0],
        _borderWidth: split[1],
        _borderColor: split[2],
      };
    }
  }
  return {
    _borderType: "none",
    _borderWidth: "0",
    _borderColor: "none",
  };
}

function getPaddingSpecs(padding) {
  if (padding && padding.length) {
    const split = padding.split(" ");
    if (split.length === 4) {
      return {
        _paddingTop: split[0].replace(/px/gi, ""),
        _paddingRight: split[1].replace(/px/gi, ""),
        _paddingBottom: split[2].replace(/px/gi, ""),
        _paddingLeft: split[3].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }

    if (split.length === 2) {
      return {
        _paddingTop: split[0].replace(/px/gi, ""),
        _paddingRight: split[1].replace(/px/gi, ""),
        _paddingBottom: split[0].replace(/px/gi, ""),
        _paddingLeft: split[1].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }

    if (split.length === 1) {
      return {
        _paddingTop: split[0].replace(/px/gi, ""),
        _paddingRight: split[0].replace(/px/gi, ""),
        _paddingBottom: split[0].replace(/px/gi, ""),
        _paddingLeft: split[0].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }
  }
  return {
    _paddingTop: "0",
    _paddingRight: "0",
    _paddingBottom: "0",
    _paddingLeft: "0",
  };
}

function getMarginSpecs(margin) {
  if (margin && margin.length) {
    const split = margin.split(" ");
    if (split.length === 4) {
      return {
        _marginTop: split[0].replace(/px/gi, ""),
        _marginRight: split[1].replace(/px/gi, ""),
        _marginBottom: split[2].replace(/px/gi, ""),
        _marginLeft: split[3].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }

    if (split.length === 2) {
      return {
        _marginTop: split[0].replace(/px/gi, ""),
        _marginRight: split[1].replace(/px/gi, ""),
        _marginBottom: split[0].replace(/px/gi, ""),
        _marginLeft: split[1].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }

    if (split.length === 1) {
      return {
        _marginTop: split[0].replace(/px/gi, ""),
        _marginRight: split[0].replace(/px/gi, ""),
        _marginBottom: split[0].replace(/px/gi, ""),
        _marginLeft: split[0].replace(/px/gi, "").replace(/;/gi, ""),
      };
    }
  }
  return {
    _marginTop: "0",
    _marginRight: "0",
    _marginBottom: "0",
    _marginLeft: "0",
  };
}

function CustomizerUI({ item, api, type, NotificationCenter, saved }) {
  const { _borderType, _borderWidth, _borderColor } = getBorderSpecs(
    item.border
  );
  const {
    _paddingTop,
    _paddingRight,
    _paddingBottom,
    _paddingLeft,
  } = getPaddingSpecs(item.padding);
  const {
    _marginTop,
    _marginRight,
    _marginBottom,
    _marginLeft,
  } = getMarginSpecs(item.margin);
  const [text, setText] = React.useState(item.text || "");
  const [keyword, setKeyword] = React.useState(item.keyword || "");
  const [order, setOrder] = React.useState(item.order || "");
  const [url, setUrl] = React.useState(item.url || "");
  const [width, setWidth] = React.useState(item.width || "");
  const [cellWidth, setCellWidth] = React.useState(item.cellWidth || "");
  const [leading, setLeading] = React.useState(item.leading || "");
  const [trailing, setTrailing] = React.useState(item.trailing || "");
  const [fontFamily, setFontFamily] = React.useState(item.fontFamily || "");
  const [fontWeight, setFontWeight] = React.useState(item.fontWeight || "");
  const [label, setLabel] = React.useState(item.label || "");
  const [textAlign, setTextAlign] = React.useState(item.textAlign || "");
  const [borderType, setBorderType] = React.useState(_borderType);
  const [borderWidth, setBorderWidth] = React.useState(
    _borderWidth ? _borderWidth.replace(/px/gi, "").replace(/;/gi, "") : ""
  );
  const [borderColor, setBorderColor] = React.useState(_borderColor);
  const [borderRadius, setBorderRadius] = React.useState(
    item.borderRadius
      ? item.borderRadius.replace(/px/gi, "").replace(/;/gi, "")
      : ""
  );
  const [fontSize, setFontSize] = React.useState(
    item.fontSize ? item.fontSize.replace(/px/gi, "").replace(/;/gi, "") : ""
  );
  const [backgroundColor, setBackgroundColor] = React.useState(
    item.backgroundColor
  );
  const [paddingTop, setPaddingTop] = React.useState(_paddingTop);
  const [display, setDisplay] = React.useState(item.display || "block");
  const [paddingRight, setPaddingRight] = React.useState(_paddingRight);
  const [paddingBottom, setPaddingBottom] = React.useState(_paddingBottom);
  const [paddingLeft, setPaddingLeft] = React.useState(_paddingLeft);
  const [marginTop, setMarginTop] = React.useState(_marginTop);
  const [marginRight, setMarginRight] = React.useState(_marginRight);
  const [alignItem, setAlignItem] = React.useState(item.alignItem);
  const [marginBottom, setMarginBottom] = React.useState(_marginBottom);
  const [marginLeft, setMarginLeft] = React.useState(_marginLeft);
  const [color, setColor] = React.useState(item.color || "rgba(0,0,0,1)");
  const [loading, setLoading] = React.useState(false);
  const [jsonMode, setJsonMode] = React.useState(false);
  const [json, setJson] = React.useState(JSON.stringify(item, null, "\t"));

  const classes = useStyles(styles);

  const save = (l, v, a) => {
    setLoading(true);
    if (type === "List") {
      a.updateCourierList(item.id, { [l]: v }).then(() => {
        setLoading(false);
      });
    }

    if (type === "Item") {
      a.updateCourierItem(item.id, { [l]: v }).then(() => {
        setLoading(false);
      });
    }
    saved();
  };

  const saveJSON = (a) => {
    setLoading(true);
    if (type === "List") {
      a.updateCourierList(item.id, JSON.parse(json)).then(() => {
        setLoading(false);
      });
    }

    if (type === "Item") {
      a.updateCourierItem(item.id, JSON.parse(json)).then(() => {
        setLoading(false);
      });
    }
    saved();
  };

  const savePadding = () => {
    save(
      "padding",
      `${paddingTop === "auto" ? "auto" : `${paddingTop}px`} ${
        paddingRight === "auto" ? "auto" : `${paddingRight}px`
      } ${paddingBottom === "auto" ? "auto" : `${paddingBottom}px`} ${
        paddingLeft === "auto" ? "auto" : `${paddingLeft}px`
      }`,
      api
    );
  };

  const saveMargin = () => {
    save(
      "margin",
      `${marginTop === "auto" ? "auto" : `${marginTop}px`} ${
        marginRight === "auto" ? "auto" : `${marginRight}px`
      } ${marginBottom === "auto" ? "auto" : `${marginBottom}px`} ${
        marginLeft === "auto" ? "auto" : `${marginLeft}px`
      }`,
      api
    );
  };

  const confirmDeletion = () => {
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: `The ${type} will be Deleted`,
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: () => {
            if (type === "Item") {
              api.deleteCourierItem(item.id);
            }
            if (type === "List") {
              api.deleteCourierList(item.id);
            }
          },
        },
      }
    );
  };

  let ui;

  if (jsonMode) {
    ui = (
      <Grid item xs={12}>
        <Typography variant="overline" color="textSecondary">
          Label
        </Typography>
        <br />
        <TextField
          id="json"
          fullWidth
          multiline
          size="small"
          placeholder="JSON"
          value={json}
          onBlur={() => saveJSON(api)}
          variant="outlined"
          onChange={(e) => setJson(e.target.value)}
        />
      </Grid>
    );
  } else {
    ui = (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Label
          </Typography>
          <br />
          <TextField
            id="label"
            fullWidth
            size="small"
            placeholder="Shown on builder for easy identification."
            value={label}
            onBlur={() => save("label", label, api)}
            variant="outlined"
            onChange={(e) => setLabel(e.target.value)}
          />
        </Grid>
        {type === "List" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Keyword
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={keyword}
                onBlur={() => save("keyword", keyword, api)}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Text
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={text}
                multiline
                onBlur={() => save("text", text, api)}
                onChange={(e) => setText(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Url
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={url}
                multiline
                onBlur={() => save("url", url, api)}
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Font Family
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={fontFamily}
                multiline
                onBlur={() => save("fontFamily", fontFamily, api)}
                onChange={(e) => setFontFamily(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Font Weight
            </Typography>
            <br />
            <FormControl fullWidth>
              <Select
                id="border-type"
                value={fontWeight}
                onChange={(e) => {
                  setFontWeight(e.target.value);
                  save("fontWeight", e.target.value, api);
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={"normal"}>Normal</MenuItem>
                <MenuItem value={"bold"}>Bold</MenuItem>
                <MenuItem value={"bolder"}>Bolder</MenuItem>
                <MenuItem value={"lighter"}>Lighter</MenuItem>
                <MenuItem value={"100"}>100</MenuItem>
                <MenuItem value={"200"}>200</MenuItem>
                <MenuItem value={"300"}>300</MenuItem>
                <MenuItem value={"400"}>400</MenuItem>
                <MenuItem value={"500"}>500</MenuItem>
                <MenuItem value={"600"}>600</MenuItem>
                <MenuItem value={"700"}>700</MenuItem>
                <MenuItem value={"800"}>800</MenuItem>
                <MenuItem value={"900"}>900</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Text Align
            </Typography>
            <br />
            <ToggleButtonGroup
              value={textAlign}
              exclusive
              size="small"
              onChange={(e, v) => {
                save("textAlign", v, api);
                setTextAlign(v);
              }}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <FormatAlignRightIcon />
              </ToggleButton>
              <ToggleButton value="justify" aria-label="justified">
                <FormatAlignJustifyIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Text Color
            </Typography>
            <ColorPicker
              onChange={(c) => {
                setColor(c);
                save("color", c, api);
              }}
              color={color}
            />
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Background Color
          </Typography>
          <ColorPicker
            onChange={(c) => {
              setBackgroundColor(c);
              save("backgroundColor", c, api);
            }}
            color={backgroundColor}
          />
        </Grid>
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Font Size
            </Typography>
            <Slider
              value={fontSize}
              step={1}
              min={0}
              max={96}
              valueLabelDisplay="auto"
              color="secondary"
              onChangeCommitted={() => {
                save("fontSize", `${fontSize}px`, api);
              }}
              onChange={(e, v) => {
                setFontSize(v);
              }}
            />
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Border
          </Typography>
          <br />
          <Grid container>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="border-type">Type</InputLabel>
                <Select
                  id="border-type"
                  value={borderType}
                  onChange={(e) => {
                    setBorderType(e.target.value);
                    save(
                      "border",
                      `${e.target.value} ${borderWidth}px ${borderColor}`,
                      api
                    );
                  }}
                  input={<BootstrapInput />}
                >
                  <MenuItem value={"dotted"}>Dotted</MenuItem>
                  <MenuItem value={"dashed"}>Dashed</MenuItem>
                  <MenuItem value={"solid"}>Solid</MenuItem>
                  <MenuItem value={"double"}>Double</MenuItem>
                  <MenuItem value={"groove"}>Groove</MenuItem>
                  <MenuItem value={"ridge"}>Ridge</MenuItem>
                  <MenuItem value={"inset"}>Inset</MenuItem>
                  <MenuItem value={"outset"}>Outset</MenuItem>
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"hidden"}>Hidden</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel htmlFor="border-width">Width</InputLabel>
                <BootstrapInput
                  id="border-width"
                  value={borderWidth}
                  onChange={(e) => setBorderWidth(e.target.value)}
                  onBlur={() =>
                    save(
                      "border",
                      `${borderType} ${borderWidth}px ${borderColor}`,
                      api
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <ColorPicker
                onChange={(c) => {
                  setBorderColor(c);
                  save("border", `${borderType} ${borderWidth}px ${c}`, api);
                }}
                color={borderColor}
                label="Color"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Border Radius
          </Typography>
          <Slider
            value={borderRadius}
            step={1}
            marks
            min={0}
            max={20}
            valueLabelDisplay="auto"
            color="secondary"
            onChangeCommitted={() => {
              save("borderRadius", `${borderRadius}px`, api);
            }}
            onChange={(e, v) => {
              setBorderRadius(v);
            }}
          />
        </Grid>
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Display
            </Typography>
            <br />
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="border-type">Value</InputLabel>
                  <Select
                    id="border-type"
                    value={display}
                    onChange={(e) => {
                      setDisplay(e.target.value);
                      save("display", e.target.value, api);
                    }}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"inline"}>Inline</MenuItem>
                    <MenuItem value={"block"}>Block</MenuItem>
                    <MenuItem value={"contents"}>Contents</MenuItem>
                    <MenuItem value={"flex"}>Flex</MenuItem>
                    <MenuItem value={"grid"}>Grid</MenuItem>
                    <MenuItem value={"inline-block"}>Inline Block</MenuItem>
                    <MenuItem value={"inline-flex"}>Inline Flex</MenuItem>
                    <MenuItem value={"inline-grid"}>Inline Grid</MenuItem>
                    <MenuItem value={"inline-table"}>Inline Table</MenuItem>
                    <MenuItem value={"hiddlist-itemen"}>List Item</MenuItem>
                    <MenuItem value={"run-in"}>Run In</MenuItem>
                    <MenuItem value={"table"}>Table</MenuItem>
                    <MenuItem value={"table-caption"}>Table Caption</MenuItem>
                    <MenuItem value={"table-column-group"}>
                      Table Column Group
                    </MenuItem>
                    <MenuItem value={"table-header-group"}>
                      Table Header Group
                    </MenuItem>
                    <MenuItem value={"table-footer-group"}>
                      Table Footer Group
                    </MenuItem>
                    <MenuItem value={"table-row-group"}>
                      Table Row Group
                    </MenuItem>
                    <MenuItem value={"table-cell"}>Table Cell</MenuItem>
                    <MenuItem value={"table-column"}>Table Column</MenuItem>
                    <MenuItem value={"table-row"}>Table Row</MenuItem>
                    <MenuItem value={"none"}>None</MenuItem>
                    <MenuItem value={"initial"}>Initial</MenuItem>
                    <MenuItem value={"inherit"}>Inherit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Width
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={width}
                multiline
                onBlur={() => save("width", width, api)}
                onChange={(e) => setWidth(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Cell Width
            </Typography>
            <br />
            <FormControl fullWidth>
              <BootstrapInput
                id="text"
                value={cellWidth}
                multiline
                onBlur={() => save("cellWidth", cellWidth, api)}
                onChange={(e) => setCellWidth(e.target.value)}
              />
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        {type === "Item" ? (
          <Grid item xs={12}>
            <Typography variant="overline" color="textSecondary">
              Align Item
            </Typography>
            <br />
            <FormControl fullWidth>
              <Select
                id="border-type"
                value={alignItem}
                onChange={(e) => {
                  setAlignItem(e.target.value);
                  save("alignItem", e.target.value, api);
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={"stretch"}>Stretch</MenuItem>
                <MenuItem value={"center"}>Center</MenuItem>
                <MenuItem value={"flex-start"}>Flex Start</MenuItem>
                <MenuItem value={"flex-end"}>Flex End</MenuItem>
                <MenuItem value={"baseline"}>Baseline</MenuItem>
                <MenuItem value={"initial"}>Initial</MenuItem>
                <MenuItem value={"inherit"}>Inherit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="overline" color="textSecondary">
                Padding
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.revert}
                onClick={() => {
                  setPaddingTop(0);
                  setPaddingRight(0);
                  setPaddingBottom(0);
                  setPaddingLeft(0);
                  save("padding", "0px 0px 0px 0px", api);
                }}
              >
                <SettingsBackupRestoreIcon
                  style={{ color: "white", fontSize: 10 }}
                />{" "}
                Revert
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel htmlFor="padding-top">Top</InputLabel>
                <BootstrapInput
                  id="padding-top"
                  value={paddingTop}
                  onChange={(e) => {
                    setPaddingTop(e.target.value);
                  }}
                  onBlur={savePadding}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel htmlFor="padding-right">Right</InputLabel>
                <BootstrapInput
                  id="padding-right"
                  value={paddingRight}
                  onChange={(e) => {
                    setPaddingRight(e.target.value);
                  }}
                  onBlur={savePadding}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel htmlFor="padding-bottom">Bottom</InputLabel>
                <BootstrapInput
                  id="padding-bottom"
                  value={paddingBottom}
                  onChange={(e) => {
                    setPaddingBottom(e.target.value);
                  }}
                  onBlur={savePadding}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel htmlFor="padding-left">Left</InputLabel>
                <BootstrapInput
                  id="padding-left"
                  value={paddingLeft}
                  onChange={(e) => {
                    setPaddingLeft(e.target.value);
                  }}
                  onBlur={savePadding}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {true ? (
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="overline" color="textSecondary">
                  Margin
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.revert}
                  onClick={() => {
                    setMarginTop(0);
                    setMarginRight(0);
                    setMarginBottom(0);
                    setMarginLeft(0);
                    save("margin", "0px 0px 0px 0px", api);
                  }}
                >
                  <SettingsBackupRestoreIcon
                    style={{ color: "white", fontSize: 10 }}
                  />{" "}
                  Revert
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}>
                <FormControl>
                  <InputLabel htmlFor="padding-top">Top</InputLabel>
                  <BootstrapInput
                    id="padding-top"
                    value={marginTop}
                    onChange={(e) => {
                      setMarginTop(e.target.value);
                    }}
                    onBlur={saveMargin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <InputLabel htmlFor="margin-right">Right</InputLabel>
                  <BootstrapInput
                    id="margin-right"
                    value={marginRight}
                    onChange={(e) => {
                      setMarginRight(e.target.value);
                    }}
                    onBlur={saveMargin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <InputLabel htmlFor="margin-bottom">Bottom</InputLabel>
                  <BootstrapInput
                    id="margin-bottom"
                    value={marginBottom}
                    onChange={(e) => {
                      setMarginBottom(e.target.value);
                    }}
                    onBlur={saveMargin}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <InputLabel htmlFor="margin-left">Left</InputLabel>
                  <BootstrapInput
                    id="margin-left"
                    value={marginLeft}
                    onChange={(e) => {
                      setMarginLeft(e.target.value);
                    }}
                    onBlur={saveMargin}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Order
          </Typography>
          <br />
          <FormControl fullWidth>
            <BootstrapInput
              id="text"
              value={order}
              onBlur={() => save("order", Number(order), api)}
              onChange={(e) => setOrder(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Leading
          </Typography>
          <br />
          <FormControl fullWidth>
            <BootstrapInput
              id="text"
              value={leading}
              onBlur={() => save("leading", leading, api)}
              onChange={(e) => setLeading(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Trailing
          </Typography>
          <br />
          <FormControl fullWidth>
            <BootstrapInput
              id="text"
              value={trailing}
              onBlur={() => save("trailing", trailing, api)}
              onChange={(e) => setTrailing(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", margin: 16 }}>
          <Button
            onClick={confirmDeletion}
            variant="outlined"
            style={{ color: "#f44336", borderColor: "#f44336" }}
          >
            Delete {type}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.container}>
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Avatar style={{ background: "#e0e0e0" }}>
                    <PaletteIcon style={{ color: "black" }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h6">Customizer</Typography>
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item>
                <CircularProgress size={30} style={{ color: "#2196f3" }} />
              </Grid>
            ) : (
              []
            )}
            <Grid item>
              <Chip
                label={`Text Mode`}
                style={{ marginRight: 8 }}
                size="small"
                color={jsonMode ? "primary" : undefined}
                onClick={() => {
                  setJsonMode(!jsonMode);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {ui}
      </Grid>
    </div>
  );
}
