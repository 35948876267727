// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperSubscriptionsPage from "../component/WrapperSubscriptionsPage";

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  eventReasons: state.eventReasons,
  subscriptionRules: state.subscriptionRules,
  rules: state.rules,
});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (...args) => dispatch(Actions.getSubscriptions(...args)),
  getSubscriptionById: (...args) =>
    dispatch(Actions.getSubscriptionById(...args)),
  createSubscription: (...args) =>
    dispatch(Actions.createSubscription(...args)),
  deleteSubscription: (...args) =>
    dispatch(Actions.deleteSubscription(...args)),
  updateSubscription: (...args) =>
    dispatch(Actions.updateSubscription(...args)),

  getEventReasons: () => dispatch(Actions.getEventReasons()),
  getEventReasonById: (...args) =>
    dispatch(Actions.getEventReasonById(...args)),
  createEventReason: (...args) => dispatch(Actions.createEventReason(...args)),
  deleteEventReason: (...args) => dispatch(Actions.deleteEventReason(...args)),
  updateEventReason: (...args) => dispatch(Actions.updateEventReason(...args)),

  getRules: () => dispatch(Actions.getRules()),
  getRuleById: (...args) => dispatch(Actions.getRuleById(...args)),
  createRule: (...args) => dispatch(Actions.createRule(...args)),
  deleteRule: (...args) => dispatch(Actions.deleteRule(...args)),
  updateRule: (...args) => dispatch(Actions.updateRule(...args)),

  searchUsers: (...args) => dispatch(Actions.searchUsers(...args)),
  updateSubscriptionRule: (...args) =>
    dispatch(Actions.updateSubscriptionRule(...args)),
  createSubscriptionRule: (...args) =>
    dispatch(Actions.createSubscriptionRule(...args)),
});
class SubscriptionsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    subscriptions: PropTypes.array,
    getSubscriptions: PropTypes.func,
    getSubscriptionById: PropTypes.func,
    createSubscription: PropTypes.func,
    deleteSubscription: PropTypes.func,
    updateSubscription: PropTypes.func,

    eventReasons: PropTypes.array,
    getEventReasons: PropTypes.func,
    getEventReasonById: PropTypes.func,
    createEventReason: PropTypes.func,
    deleteEventReason: PropTypes.func,
    updateEventReason: PropTypes.func,

    rules: PropTypes.array,
    getRules: PropTypes.func,
    getRuleById: PropTypes.func,
    createRule: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,

    updateSubscriptionRule: PropTypes.func,
    createSubscriptionRule: PropTypes.func,

    searchUsers: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, subscriptions } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: subscriptions.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getSubscriptions, getEventReasons, getRules } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    await Promise.all([
      getSubscriptions(urlParams.filters || []),
      getEventReasons(),
      getRules(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      subscriptions,
      getSubscriptionById,
      createSubscription,
      deleteSubscription,
      updateSubscription,
      eventReasons,
      getEventReasonById,
      createEventReason,
      deleteEventReason,
      updateEventReason,
      subscriptionRules,
      getSubscriptions,
      searchUsers,
      rules,
      getRuleById,
      createRule,
      deleteRule,
      updateRule,
      updateSubscriptionRule,
      createSubscriptionRule,
    } = this.props;

    const api = {
      getSubscriptions,
      getSubscriptionById,
      createSubscription,
      deleteSubscription,
      updateSubscription,

      getEventReasonById,
      createEventReason,
      deleteEventReason,
      updateEventReason,

      getRuleById,
      createRule,
      deleteRule,
      updateRule,

      updateSubscriptionRule,
      createSubscriptionRule,
    };

    const { urlParams, loading } = this.state;

    return (
      <WrapperSubscriptionsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        subscriptions={subscriptions}
        eventReasons={eventReasons}
        rules={rules}
        searchUsers={searchUsers}
        api={api}
        history={history}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
