import { networkAction } from "helpers/network/networkAction";

import {
  GET_TEMPLATES,
  GET_TEMPLATE_BY_ID,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  CREATE_TEMPLATE,
} from "constants/templates";

import * as Api from "api";

export const getTemplates = () => async (dispatch) =>
  networkAction(dispatch, GET_TEMPLATES, Api.getTemplates, []);

export const getTemplateById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TEMPLATE_BY_ID, Api.getTemplateById, [id]);

export const deleteTemplate = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TEMPLATE, Api.deleteTemplate, [id]);

export const createTemplate = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TEMPLATE, Api.createTemplate, [body]);

export const updateTemplate = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TEMPLATE, Api.updateTemplate, [id, body]);
