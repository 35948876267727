import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class ListItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    courierItem: PropTypes.object,
    courierListID: PropTypes.number,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
  };

  render() {
    const {
      courierItem,
      onSelect,
      selected,
      courierListID,
      classes,
    } = this.props;

    const s =
      courierItem.id === selected.courierItemID &&
      selected.courierListID === courierListID;

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(courierListID, courierItem.id);
        }}
        className={classes.root}
        style={{
          border: !s
            ? "solid 1px rgba(155,155,155,0.3)"
            : "solid 1px rgba(33,150,243, 0.3)",
          background: !s ? undefined : "rgba(33,150,243, 0.1)",
        }}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <DragIndicatorIcon
              id="grab"
              style={{
                fontSize: 12,
                color: "rgba(155,155,155)",
                cursor: "grab",
              }}
            />
            <Typography color="textSecondary" style={{ fontSize: 8 }}>
              {courierItem.id}
            </Typography>
          </Grid>
          <Grid item>
            {courierItem.label ? (
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  color: "rgba(155,155,155, 1)",
                  border: "solid 1px rgba(155,155,155, 1)",
                  borderRadius: 25,
                  padding: "2px 6px",
                }}
              >
                {courierItem.label}
              </Typography>
            ) : (
              []
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ListItem);
