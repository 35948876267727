import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/AddCircleOutline";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// components
import List from "components/List";
import LongHover from "components/LongHover";

// styles
import styles from "./styles";

const SortableItem = SortableElement(({ list, props }) => (
  <List list={list} {...props} />
));

const SortableList = SortableContainer(({ items, props }) => (
  <div>
    {items &&
      items.map((a, index) => (
        <SortableItem key={a.id} list={a} props={props} index={index} />
      ))}
  </div>
));

class Lists extends Component {
  static propTypes = {
    classes: PropTypes.object,
    lists: PropTypes.object,
    onSelect: PropTypes.func,
    parentID: PropTypes.number,
    reorder: PropTypes.bool,
    selected: PropTypes.object,
    api: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { lists } = this.props;

    this.state = {
      lists,
      itemID: "",
    };
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { api } = this.props;
    const { updateCourierList } = api;
    const listToUpdate = this.state.lists[oldIndex];
    updateCourierList(listToUpdate.id, { order: newIndex + 1 });
    this.setState(({ lists }) => ({
      lists: arrayMove(lists, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    if (
      !(
        e.target.id === "grab" ||
        (e.target.nearestViewportElement &&
          e.target.nearestViewportElement.id === "grab")
      )
    ) {
      return true; // Return true to cancel sorting
    }
  };

  render() {
    const { classes, onSelect, selected, parentID, api } = this.props;
    const { lists, anchorEl, dialogOpen, itemID } = this.state;

    return (
      <Grid container justify="space-between" alignItems="center">
        <Grid item style={{ flexGrow: 1 }}>
          <SortableList
            axis="y"
            items={lists || []}
            props={{
              classes,
              onSelect,
              selected,
              api,
            }}
            onSortEnd={this.onSortEnd}
            shouldCancelStart={this.shouldCancelStart.bind(this)}
            key="0"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Tooltip title="Add a Row">
            <LongHover onLongHover={(aEl) => this.setState({ anchorEl: aEl })}>
              <IconButton
                size="small"
                onClick={() => {
                  api.createCourierList({
                    parentID,
                  });
                }}
              >
                <Add style={{ fontSize: 18 }} />
              </IconButton>
            </LongHover>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
          >
            <MenuItem
              onClick={() => {
                api.createCourierList({
                  parentID,
                });
              }}
            >
              New List
            </MenuItem>
            <MenuItem onClick={() => this.setState({ dialogOpen: true })}>
              Duplicate From Existing List
            </MenuItem>
          </Menu>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            What is the id of the existing list?
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              size="small"
              margin="dense"
              label="Item ID"
              value={itemID}
              onChange={(e) => this.setState({ itemID: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogOpen: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                api.getCourierListById(Number(itemID)).then((r) => {
                  api.createCourierList({
                    ...r.payload,
                    id: undefined,
                    order: undefined,
                    parentID,
                  });
                });
              }}
              color="primary"
            >
              Duplicate
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(Lists);
