import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperTagsPage from "../component/WrapperTagsPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  tags: state.tags,
});

const mapDispatchToProps = (dispatch) => ({
  getTags: () => dispatch(Actions.getTags()),
  getTagById: (...args) => dispatch(Actions.getTagById(...args)),
  createTag: (...args) => dispatch(Actions.createTag(...args)),
  deleteTag: (...args) => dispatch(Actions.deleteTag(...args)),
  updateTag: (...args) => dispatch(Actions.updateTag(...args)),
});
class TagsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    tags: PropTypes.array,
    getTags: PropTypes.func,
    getTagById: PropTypes.func,
    createTag: PropTypes.func,
    deleteTag: PropTypes.func,
    updateTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, tags } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: tags.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getTags } = this.props;
    await Promise.all([getTags()]);

    this.setState({ loading: false });
  }

  render() {
    const { tags, getTagById, createTag, deleteTag, updateTag } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperTagsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        tags={tags}
        getTagById={getTagById}
        createTag={createTag}
        deleteTag={deleteTag}
        updateTag={updateTag}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);
