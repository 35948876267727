import { networkAction } from "helpers/network/networkAction";

import {
  GET_COURIERS,
  GET_COURIER_BY_ID,
  DELETE_COURIER,
  UPDATE_COURIER,
  CREATE_COURIER,
  COURIER_REPLACE,
  EMAIL_SEND,
  EMAIL_HTML,
} from "constants/couriers";

import * as Api from "api";

export const getCouriers = () => async (dispatch) =>
  networkAction(dispatch, GET_COURIERS, Api.getCouriers, []);

export const courierReplace = (id, body) => async (dispatch) =>
  networkAction(dispatch, COURIER_REPLACE, Api.courierReplace, [id, body]);

export const emailSend = (id, body) => async (dispatch) =>
  networkAction(dispatch, EMAIL_SEND, Api.emailSend, [id, body]);

export const emailHTML = (id, body) => async (dispatch) =>
  networkAction(dispatch, EMAIL_HTML, Api.emailHTML, [id, body]);

export const getCourierById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COURIER_BY_ID, Api.getCourierById, [id]);

export const deleteCourier = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COURIER, Api.deleteCourier, [id]);

export const createCourier = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COURIER, Api.createCourier, [body]);

export const updateCourier = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COURIER, Api.updateCourier, [id, body]);
