import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// components
import TemplatesList from "components/TemplatesList";
import Page from "components/Page";
import FormTemplate from "components/FormTemplate";

// styles
import styles from "./styles";

class WrapperTemplatesPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //templates
    templates: PropTypes.array,
    configurations: PropTypes.array,
    getTemplateById: PropTypes.func,
    createTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedTemplate: undefined,
      formTemplateOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { templates } = this.props;

    switch (index) {
      case 0:
        return (
          <TemplatesList
            templates={templates}
            onSelect={(selectedTemplate) =>
              this.setState({
                selectedTemplate,
                formTemplateOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createTemplate,
      deleteTemplate,
      updateTemplate,
      refresh,
      configurations,
    } = this.props;

    const { index, selectedTemplate, formTemplateOpen } = this.state;

    return (
      <Page
        helmet={"Templates"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formTemplateOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Templates"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormTemplate
          open={formTemplateOpen}
          template={selectedTemplate}
          close={() => {
            this.setState({
              formTemplateOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedTemplate: undefined,
                }),
              200
            );
          }}
          createTemplate={createTemplate}
          deleteTemplate={deleteTemplate}
          updateTemplate={updateTemplate}
          configurations={configurations}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTemplatesPage);
