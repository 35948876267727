import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config/env";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CouriersList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    couriers: PropTypes.array,
    onSelect: PropTypes.func,
    getCouriers: PropTypes.func,
    history: PropTypes.object,
    onEdit: PropTypes.func,
    tags: PropTypes.array,
  };

  onSelect(selectedCourier) {
    const { onSelect } = this.props;
    this.setState({ selectedCourier });
    if (onSelect) {
      onSelect(selectedCourier);
    }
  }

  render() {
    const { couriers, onEdit, tags, getCouriers, history } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getCouriers}
        keyword="careers"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/postal/couriers/searchMetadata`}
        metaDataOveride={{
          tagID: {
            type: "autocomplete",
            data: tags,
            valueKey: "id",
            labelKey: "name",
          },
        }}
      >
        <Table
          data={couriers}
          meta={[
            {
              path: "name",
              title: "Name",
              numeric: false,
            },
            {
              path: "tagID",
              title: "Tag",
              numeric: false,
              render: (t, d) => {
                const tag = tags.find((t) => t.id === d.tagID);
                if (tag) {
                  return (
                    <Chip
                      size="mini"
                      label={tag.name}
                      style={{ color: "white", background: tag.color }}
                    />
                  );
                }
                return <div />;
              },
            },
            {
              path: "id",
              title: "Edit",
              width: 30,
              numeric: false,
              render: (t, d) => (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onEdit(d);
                  }}
                >
                  <Edit />
                </IconButton>
              ),
            },
          ]}
          title={"Couriers"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(CouriersList);
