import { networkAction } from "helpers/network/networkAction";

import {
  GET_COURIER_LIST_ITEMS,
  GET_COURIER_LIST_ITEM_BY_ID,
  DELETE_COURIER_LIST_ITEM,
  UPDATE_COURIER_LIST_ITEM,
  CREATE_COURIER_LIST_ITEM,
} from "constants/courierItems";

import * as Api from "api";

export const getCourierItems = () => async (dispatch) =>
  networkAction(dispatch, GET_COURIER_LIST_ITEMS, Api.getCourierItems, []);

export const getCourierItemById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COURIER_LIST_ITEM_BY_ID, Api.getCourierItemById, [
    id,
  ]);

export const deleteCourierItem = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COURIER_LIST_ITEM, Api.deleteCourierItem, [
    id,
  ]);

export const createCourierItem = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COURIER_LIST_ITEM, Api.createCourierItem, [
    body,
  ]);

export const updateCourierItem = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COURIER_LIST_ITEM, Api.updateCourierItem, [
    id,
    body,
  ]);
