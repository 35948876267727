import { networkAction } from 'helpers/network/networkAction';

import {
  GET_EVENT_REASONS,
  GET_EVENT_REASON_BY_ID,
  DELETE_EVENT_REASON,
  UPDATE_EVENT_REASON,
  CREATE_EVENT_REASON,
} from 'constants/eventReasons';

import * as Api from 'api';

export const getEventReasons = () => async dispatch => networkAction(
  dispatch,
  GET_EVENT_REASONS,
  Api.getEventReasons,
  []
);

export const getEventReasonById = (id) => async dispatch => networkAction(
  dispatch,
  GET_EVENT_REASON_BY_ID,
  Api.getEventReasonById,
  [id]
);

export const deleteEventReason = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_EVENT_REASON,
  Api.deleteEventReason,
  [id]
);

export const createEventReason = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_EVENT_REASON,
  Api.createEventReason,
  [body]
);

export const updateEventReason = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_EVENT_REASON,
  Api.updateEventReason,
  [id, body]
);
