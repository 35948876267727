import { COURIERS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import { serviceRoles } from "config/access";
import CouriersPage from "./container/CouriersPage";

export default function getRoute(userAuth) {
  const { account, postal } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [postal.name]: [postal.roles.user],
  };
  return {
    path: COURIERS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: CouriersPage,
  };
}
