import React from "react";
import { SketchPicker } from "react-color";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ColorPicker({ onChange, label, color }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [col, setCol] = React.useState(
    color && color.length && color !== "none" ? color : "rgba(255,255,255,0.1)"
  );
  const classes = useStyles(styles);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {label
        ? [
            <Typography color="textSecondary" variant="caption">
              {label}
            </Typography>,
            <br />,
          ]
        : []}
      <div
        className={classes.swatch}
        style={{
          marginTop: label ? 6 : 0,
        }}
        onClick={handleClick}
      >
        <div className={classes.color} style={{ background: col }} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          color={col}
          onChangeComplete={(c) =>
            onChange(`rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`)
          }
          onChange={(c) => {
            setCol(`rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`);
          }}
        />
      </Popover>
    </div>
  );
}
