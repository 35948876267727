import { GET, PUT, DELETE, POST } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const couriers = `${REACT_APP_API_PREFIX}/${service}/couriers`;

export function getCouriers(filters) {
  const url = `${couriers}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCourierById(id) {
  const url = `${couriers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function courierReplace(id, body) {
  const url = `${couriers}/${id}/replace`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function emailSend(id, body) {
  const url = `${couriers}/${id}/emailSend`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function emailHTML(id, body) {
  const url = `${couriers}/${id}/emailHTML`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateCourier(id, body) {
  const url = `${couriers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCourier(id) {
  const url = `${couriers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCourier(body) {
  const url = `${couriers}`;
  return {
    method: POST,
    url,
    body,
  };
}
