import red from "@material-ui/core/colors/red";

export default (theme) => ({
  container: {
    padding: 5,
  },
  delete: {
    color: red[500],
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  textField: {
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  paper: {
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
  rule: {
    marginTop: 12,
    padding: 12,
    display: "flex",
  },
  ruleDescription: {
    flex: 3,
  },
  interaction: {
    flex: 1,
    textAlign: "right",
  },
});
