import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Sent from "@material-ui/icons/SendTwoTone";
import Warning from "@material-ui/icons/WarningTwoTone";
import Chip from "@material-ui/core/Chip";

// custom
import DatetimeGraph from "components/DatetimeGraph";
import Widget from "components/Widget";

// helpers
import { formattedPrice } from "helpers/stripe";

// styles
import styles from "./styles";

class EmailDashboard extends Component {
  static propTypes = {
    dashboard: PropTypes.object,
  };

  state = {
    range: "week",
  };

  getRevenue() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let total = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        const element = dashboard[range][key];
        if (!element.refunded) {
          total += element.total;
        }
      }
    }

    return formattedPrice(total);
  }

  getPulses() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let pulses = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        if (!dashboard[range][key].sent) pulses += 1;
      }
    }
    return pulses;
  }

  getCount() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let pulses = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        pulses += 1;
      }
    }

    return pulses;
  }

  getRefunds() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let pulses = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        const element = dashboard[range][key];
        if (element.refunded) {
          pulses += 1;
        }
      }
    }

    return pulses;
  }

  render() {
    const { dashboard } = this.props;

    const { range } = this.state;

    if (dashboard === undefined) {
      return <div />;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "week" })}
                variant={range === "week" ? "container" : "outlined"}
                color={range === "week" ? "primary" : undefined}
                label="Week to Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "month" })}
                variant={range === "month" ? "container" : "outlined"}
                color={range === "month" ? "primary" : undefined}
                label="Month To Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "year" })}
                variant={range === "year" ? "container" : "outlined"}
                color={range === "year" ? "primary" : undefined}
                label="Year To Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "previous" })}
                variant={range === "previous" ? "container" : "outlined"}
                color={range === "previous" ? "primary" : undefined}
                label="Previous Year"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            icon={<Sent style={{ color: "white" }} />}
            title={"Sent"}
            subtitle={"Successfully Sent"}
            color={"#ff9800"}
            getValue={this.getCount.bind(this)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            icon={<Warning style={{ color: "white" }} />}
            title={"Issues"}
            subtitle={"Emails not sent"}
            color={"#f57c00"}
            getValue={this.getPulses.bind(this)}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Widget
            icon={(<SettingsBackupRestore style={{ color: 'white' }} />)}
            title={'Number of Refunds'}
            subtitle={'Refund(s)'}
            color={'#ef6c00'}
            getValue={this.getRefunds.bind(this)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Paper>
            {dashboard[range] && dashboard[range].length ? (
              <DatetimeGraph
                data={dashboard[range].sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                )}
                key={range}
                height={200}
                dateKey={"createdAt"}
                color={"#ff9800"}
              />
            ) : (
              []
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(EmailDashboard);
