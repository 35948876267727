import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SUBSCRIPTION_RULES,
  GET_SUBSCRIPTION_RULE_BY_ID,
  DELETE_SUBSCRIPTION_RULE,
  UPDATE_SUBSCRIPTION_RULE,
  CREATE_SUBSCRIPTION_RULE,
} from 'constants/subscriptionRules';

import * as Api from 'api';

export const getSubscriptionRules = () => async dispatch => networkAction(
  dispatch,
  GET_SUBSCRIPTION_RULES,
  Api.getSubscriptionRules,
  []
);

export const getSubscriptionRuleById = (id) => async dispatch => networkAction(
  dispatch,
  GET_SUBSCRIPTION_RULE_BY_ID,
  Api.getSubscriptionRuleById,
  [id]
);

export const deleteSubscriptionRule = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_SUBSCRIPTION_RULE,
  Api.deleteSubscriptionRule,
  [id]
);

export const createSubscriptionRule = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_SUBSCRIPTION_RULE,
  Api.createSubscriptionRule,
  [body]
);

export const updateSubscriptionRule = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SUBSCRIPTION_RULE,
  Api.updateSubscriptionRule,
  [id, body]
);
