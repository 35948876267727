import { networkAction } from "helpers/network/networkAction";

import {
  GET_EMAILS,
  GET_EMAIL_BY_ID,
  DELETE_EMAIL,
  UPDATE_EMAIL,
  CREATE_EMAIL,
  FORWARD_EMAIL,
  GET_EMAIL_DASHBOARD,
} from "constants/emails";

import * as Api from "api";

export const getEmails = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_EMAILS, Api.getEmails, [filters]);

export const getEmailDashboard = () => async (dispatch) =>
  networkAction(dispatch, GET_EMAIL_DASHBOARD, Api.getEmailDashboard, []);

export const getEmailById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EMAIL_BY_ID, Api.getEmailById, [id]);

export const forwardEmail = (id, body) => async (dispatch) =>
  networkAction(dispatch, FORWARD_EMAIL, Api.forwardEmail, [id, body]);

export const deleteEmail = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EMAIL, Api.deleteEmail, [id]);

export const createEmail = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EMAIL, Api.createEmail, [body]);

export const updateEmail = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EMAIL, Api.updateEmail, [id, body]);
