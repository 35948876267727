import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const uploads = `${REACT_APP_API_PREFIX}/unity/uploads`;

export function upload(file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${uploads}`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getUploads() {
  const url = `${uploads}`;
  return {
    method: GET,
    url,
  };
}

export function getUploadById(id) {
  const url = `${uploads}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateUpload(id, body) {
  const url = `${uploads}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteUpload(id) {
  const url = `${uploads}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createUpload(body) {
  const url = `${uploads}`;
  return {
    method: POST,
    url,
    body,
  };
}
