import {
  GET_RULES,
} from 'constants/rules';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_RULES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
