import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "subs";
const rules = `${REACT_APP_API_PREFIX}/${service}/rules`;

export function getRules(filters) {
  const url = `${rules}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getRuleById(id) {
  const url = `${rules}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateRule(id, body) {
  const url = `${rules}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteRule(id) {
  const url = `${rules}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createRule(body) {
  const url = `${rules}`;
  return {
    method: POST,
    url,
    body,
  };
}
