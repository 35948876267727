import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// components
import ConfigurationsList from "components/ConfigurationsList";
import Page from "components/Page";
import FormConfiguration from "components/FormConfiguration";

// styles
import styles from "./styles";

class WrapperConfigurationsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //configurations
    configurations: PropTypes.array,
    getConfigurationById: PropTypes.func,
    createConfiguration: PropTypes.func,
    deleteConfiguration: PropTypes.func,
    updateConfiguration: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedConfiguration: undefined,
      formConfigurationOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { configurations } = this.props;

    switch (index) {
      case 0:
        return (
          <ConfigurationsList
            configurations={configurations}
            onSelect={(selectedConfiguration) =>
              this.setState({
                selectedConfiguration,
                formConfigurationOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createConfiguration,
      deleteConfiguration,
      updateConfiguration,
      refresh,
    } = this.props;

    const { index, selectedConfiguration, formConfigurationOpen } = this.state;

    return (
      <Page
        helmet={"Configurations"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formConfigurationOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Configurations"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormConfiguration
          open={formConfigurationOpen}
          configuration={selectedConfiguration}
          close={() => {
            this.setState({
              formConfigurationOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedConfiguration: undefined,
                }),
              200
            );
          }}
          createConfiguration={createConfiguration}
          deleteConfiguration={deleteConfiguration}
          updateConfiguration={updateConfiguration}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperConfigurationsPage);
