import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';

// styles
import styles from './styles';

class RulesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedRule) {
    const { onSelect } = this.props;
    this.setState({ selectedRule });
    if (onSelect) {
      onSelect(selectedRule);
    }
  }

  render() {
    const { rules } = this.props;

    return (
        <Table
          data={rules}
          meta={[
            {
              path: 'name',
              title: 'Name',
              numeric: false,
              hidden: { mdDown: true },
            },
          ]}
          title={'Rules'}
          onRowSelect={this.onSelect.bind(this)}
        />
    );
  }
}

export default withStyles(styles)(RulesList);
