import subscriptionRules from './subscriptionRules';
import eventReasons from './eventReasons';
import rules from './rules';
import subscriptions from './subscriptions';
import tags from "./tags";
import emails from "./emails";
import configurations from "./configurations";
import templates from "./templates";
import couriers from "./couriers";
import errors from "./errors";
import app from "./app";
import user from "./user";
import userApps from "./userApps";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  app,
  userApps,
  couriers,
  templates,
  configurations,
  emails,
  tags,
  subscriptions,
  rules,
  eventReasons,
  subscriptionRules,
});
