import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "subs";
const subscriptionRules = `${REACT_APP_API_PREFIX}/${service}/subscriptionRules`;

export function getSubscriptionRules(filters) {
  const url = `${subscriptionRules}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getSubscriptionRuleById(id) {
  const url = `${subscriptionRules}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateSubscriptionRule(id, body) {
  const url = `${subscriptionRules}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSubscriptionRule(id) {
  const url = `${subscriptionRules}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSubscriptionRule(body) {
  const url = `${subscriptionRules}`;
  return {
    method: POST,
    url,
    body,
  };
}
