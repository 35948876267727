export default (theme) => ({
  container: {
    padding: 5,
  },
  root: {
    padding: 10,
    margin: "0px 16px",
    borderRadius: 4,
    cursor: "pointer",
    background: theme.palette.background.default,
    boxShadow: "0 3px 3px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
  },
});
