import { REACT_APP_API_PREFIX, REACT_APP_API_SIGNIN } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "account";
const users = `${REACT_APP_API_PREFIX}/${service}/users`;
const userGroups = `${REACT_APP_API_PREFIX}/${service}/userGroups`;

export function checkAccess(body) {
  const url = `${users}/access`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function searchUsers(body) {
  const url = `${users}/search`;
  return {
    method: POST,
    url,
    body,
  };
}

export function updateUserPassword(userID, body) {
  const url = `${users}/current/password`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function getUsers() {
  const url = `${users}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function impersonate(uuid) {
  const url = `${users}/${uuid}/impersonate`;
  return {
    service,
    method: POST,
    url,
  };
}

export function stopImpersonate() {
  const url = `${users}/current/stopImpersonate`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function getUserById(id) {
  const url = `${users}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function updateUser(id, body) {
  const url = `${users}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteUser(id) {
  const url = `${users}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function getCurrentUser() {
  const url = `${users}/current`;
  return {
    service,
    method: GET,
    url,
  };
}

export function createUser(body) {
  const url = `${users}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function signin(body) {
  const url = `${REACT_APP_API_PREFIX}${REACT_APP_API_SIGNIN}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function signup(body) {
  const url = `${users}/signup`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function validateEmail(token) {
  const url = `${users}/validateEmail?token=${token}`;
  return {
    service,
    method: POST,
    url,
    body: {},
  };
}

export function requestResetPassword(body) {
  const url = `${users}/requestResetPassword`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function resetPassword(body, token) {
  const url = `${users}/current/password?token=${token}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function getUserGroups(id) {
  const url = `${users}/${id}/groups`;
  return {
    service,
    method: GET,
    url,
  };
}

export function createUserGroup(id, body) {
  const url = `${users}/${id}/groups`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function deleteUserGroup(id, groupID) {
  const url = `${userGroups}/${groupID}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function getUserApps() {
  const url = `${users}/current/apps`;
  return {
    service,
    method: GET,
    url,
  };
}
