import { MY_ACCOUNT } from "constants/routes";
import { checkAccess } from "helpers/auth";
import MyAccountPage from "./container/MyAccountPage";

export default function getRoute(userAuth) {
  const requireAuth = {};
  return {
    path: MY_ACCOUNT,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: MyAccountPage,
  };
}
