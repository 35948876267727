import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperTemplatesPage from "../component/WrapperTemplatesPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  templates: state.templates,
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: () => dispatch(Actions.getTemplates()),
  getTemplateById: (...args) => dispatch(Actions.getTemplateById(...args)),
  createTemplate: (...args) => dispatch(Actions.createTemplate(...args)),
  deleteTemplate: (...args) => dispatch(Actions.deleteTemplate(...args)),
  updateTemplate: (...args) => dispatch(Actions.updateTemplate(...args)),

  getConfigurations: (...args) => dispatch(Actions.getConfigurations(...args)),
});
class TemplatesPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    templates: PropTypes.array,
    getTemplates: PropTypes.func,
    getTemplateById: PropTypes.func,
    createTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,

    getConfigurations: PropTypes.func,
    configurations: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, templates, getConfigurations } = this.props;
    getConfigurations();

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: templates.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getTemplates } = this.props;
    await Promise.all([getTemplates()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      templates,
      getTemplateById,
      createTemplate,
      deleteTemplate,
      updateTemplate,
      configurations,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperTemplatesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        templates={templates}
        getTemplateById={getTemplateById}
        createTemplate={createTemplate}
        deleteTemplate={deleteTemplate}
        updateTemplate={updateTemplate}
        configurations={configurations}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPage);
