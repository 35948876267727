import { GET, PUT, DELETE, POST } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const courierLists = `${REACT_APP_API_PREFIX}/${service}/courierLists`;

export function getCourierLists(filters) {
  const url = `${courierLists}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCourierListById(id) {
  const url = `${courierLists}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCourierList(id, body) {
  const url = `${courierLists}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCourierList(id) {
  const url = `${courierLists}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCourierList(body) {
  const url = `${courierLists}`;
  return {
    method: POST,
    url,
    body,
  };
}
