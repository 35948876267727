// material-ui
import { withStyles } from "@material-ui/core/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class SubscriptionsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    subscriptions: PropTypes.array,
    onSelect: PropTypes.func,
    getSubscriptions: PropTypes.func,
    searchUsers: PropTypes.func,
  };

  onSelect(selectedSubscription) {
    const { onSelect } = this.props;
    this.setState({ selectedSubscription });
    if (onSelect) {
      onSelect(selectedSubscription);
    }
  }

  render() {
    const {
      subscriptions,
      history,
      getSubscriptions,
      searchUsers,
    } = this.props;

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getSubscriptions}
        keyword="subscriptions"
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/subs/subscriptions/searchMetadata`}
        metaDataOveride={{
          uuid: {
            label: "User",
            type: "autoFill",
            getData: (search) =>
              searchUsers({
                text: search,
              }),
            valueKey: "uuid",
            labelTransform: (t) => `${t.firstName} ${t.lastName} (${t.email})`,
          },
        }}
      >
        <Table
          data={subscriptions}
          meta={[
            {
              path: "email",
              title: "Email",
              numeric: false,
            },
            {
              path: "token",
              title: "Token",
              numeric: false,
            },
            {
              path: "",
              title: "",
              width: 20,
              render: () => <i class="fad fa-external-link"></i>,
            },
          ]}
          title={"Subscriptions"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(SubscriptionsList);
