import { COURIER } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import CourierPage from "./container/CourierPage";

export default function getSettingsRoute(userAuth) {
  const { account, postal } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [postal.name]: [postal.roles.user],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: COURIER,
    withSidebar: true,
    withAppBar: true,
    component: CourierPage,
  };
}
