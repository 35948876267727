// routes
import {
  CONFIGURATIONS,
  COURIERS,
  EMAILS,
  SUBSCRIPTIONS,
  TAGS,
} from "constants/routes";
import React from "react";

export const menu = [
  {
    icon: <i class="fad fa-envelope-open-text"></i>,
    label: "Courrier",
    path: COURIERS,
    divider: true,
  },
  {
    icon: <i class="fad fa-sliders-v-square"></i>,
    label: "Configurations",
    path: CONFIGURATIONS,
    divider: true,
  },
  {
    icon: <i class="fad fa-tags"></i>,
    label: "Tags",
    path: TAGS,
    divider: true,
  },
  {
    icon: <i class="fad fa-bells"></i>,
    label: "Subscriptions",
    path: SUBSCRIPTIONS,
    divider: true,
  },
  {
    icon: <i class="fad fa-paper-plane"></i>,
    label: "Sent",
    path: EMAILS,
    divider: true,
  },
];
