import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class ConfigurationsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    configurations: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedConfiguration) {
    const { onSelect } = this.props;
    this.setState({ selectedConfiguration });
    if (onSelect) {
      onSelect(selectedConfiguration);
    }
  }

  render() {
    const { configurations } = this.props;

    return (
      <Table
        data={configurations}
        meta={[
          {
            path: "label",
            title: "Label",
            numeric: false,
          },
        ]}
        title={"Configurations"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ConfigurationsList);
