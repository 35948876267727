import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// styles
import styles from "./styles";

class LongHover extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onLongHover: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(...args) {
    super(...args);
    this.ref = React.createRef();
    this.state = {
      timestamp: undefined,
    };
  }

  hoverChecker(timestamp) {
    const { onLongHover } = this.props;
    if (this.state.timestamp === timestamp) {
      onLongHover(this.ref.current);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div
        ref={this.ref}
        onMouseEnter={() => {
          const timestamp = new Date().getTime();
          this.setState({ timestamp });
          setTimeout(() => this.hoverChecker(timestamp), 1500);
        }}
        onMouseLeave={() => this.setState({ timestamp: undefined })}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(LongHover);
