import * as Colors from "@material-ui/core/colors";
import { REACT_APP_SECONDARY_COLOR } from "config";

export default (theme) => {
  console.log(Colors[REACT_APP_SECONDARY_COLOR][500]);
  // debugger;
  return {
    appGrid: {
      height: 47,
      background: Colors[REACT_APP_SECONDARY_COLOR][800],
    },
    AppBarGreetings: {
      color: "white",
      padding: 12,
    },
    white: {
      color: "white",
    },
  };
};
