import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// actions
import { signin } from "actions/sessions";

// component
import WrapperDefaultPage from "../component/WrapperDefaultPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  signin: () => dispatch(signin()),
});
class DefaultPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
  };

  render() {
    const { history } = this.props;

    return <WrapperDefaultPage history={history} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
