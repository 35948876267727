import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCouriersPage from "../component/WrapperCouriersPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  couriers: state.couriers,
  tags: state.tags,
});

const mapDispatchToProps = (dispatch) => ({
  getCouriers: (...args) => dispatch(Actions.getCouriers(...args)),
  getCourierById: (...args) => dispatch(Actions.getCourierById(...args)),
  createCourier: (...args) => dispatch(Actions.createCourier(...args)),
  deleteCourier: (...args) => dispatch(Actions.deleteCourier(...args)),
  updateCourier: (...args) => dispatch(Actions.updateCourier(...args)),
  getTags: (...args) => dispatch(Actions.getTags(...args)),
});
class CouriersPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    couriers: PropTypes.array,
    getCouriers: PropTypes.func,
    getCourierById: PropTypes.func,
    createCourier: PropTypes.func,
    deleteCourier: PropTypes.func,
    updateCourier: PropTypes.func,
    getCouriers: PropTypes.func,

    getTags: PropTypes.func,
    tags: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, couriers, getTags } = this.props;
    const { UploadCenter } = this.context;

    getTags();

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: couriers.length === 0,
    };

    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("callback"),
      "application/json"
    );
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  userDidDrop(files) {
    const { createCourier } = this.props;
    const { NotificationCenter } = this.context;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
      const json = JSON.parse(evt.target.result);
      if (json) {
        createCourier(json).then((resp) => {
          if (resp.success) {
            NotificationCenter.sweetAlert({
              title: "Success",
              body: "Courier has been imported",
              success: true,
              timestamp: new Date().getTime(),
            });
            this.refresh();
          } else {
            NotificationCenter.sweetAlert({
              title: "Error",
              body: "Something went wrong. Check your JSON",
              error: true,
              timestamp: new Date().getTime(),
            });
          }
        });
      }
    };
  }

  async refresh() {
    const { getCouriers } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getCouriers(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      couriers,
      getCouriers,
      getCourierById,
      createCourier,
      deleteCourier,
      updateCourier,
      history,
      tags,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCouriersPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        couriers={couriers}
        tags={tags}
        getCouriers={getCouriers}
        history={history}
        getCourierById={getCourierById}
        createCourier={createCourier}
        deleteCourier={deleteCourier}
        updateCourier={updateCourier}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CouriersPage);
