import {
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import CopyLabel from "hooks/CopyLabel";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

function Rule({
  subRule,
  rule,
  classes,
  email,
  token,
  refresh,
  api,
  disabled,
}) {
  const [fetch, setFetch] = React.useState(subRule?.fetch || false);
  const [push, setPush] = React.useState(subRule?.push || false);

  const updateFetch = (e) => {
    const value = e.target.checked;
    setFetch(value);

    if (subRule) {
      // rule exists
      api
        .updateSubscriptionRule(subRule.relationID, {
          fetch: value,
          email,
          token,
        })
        .then(refresh);
    } else {
      // need to create new rule
      api
        .createSubscriptionRule({
          fetch: value,
          email,
          token,
          ruleID: rule.id,
        })
        .then(refresh);
    }
  };

  const updatePush = (e) => {
    const value = e.target.checked;
    setPush(value);

    if (subRule) {
      // rule exists
      api
        .updateSubscriptionRule(subRule.relationID, {
          push: value,
          email,
          token,
        })
        .then(refresh);
    } else {
      // need to create new rule
      api
        .createSubscriptionRule({
          push: value,
          email,
          token,
          ruleID: rule.id,
        })
        .then(refresh);
    }
  };

  return (
    <Paper className={classes.rule} style={{ opacity: disabled ? 0.5 : 1 }}>
      <div className={classes.ruleDescription}>
        <Typography variant="h6">{rule?.name}</Typography>
        <Typography variant="body2">{rule?.description}</Typography>
      </div>
      <div className={classes.interaction}>
        <div>
          <FormControlLabel
            labelPlacement="start"
            disabled={disabled}
            control={
              <Checkbox
                checked={push}
                onChange={updatePush}
                size="small"
                name="checkedB"
                color="primary"
              />
            }
            label="Push"
          />
        </div>
        <div>
          <FormControlLabel
            labelPlacement="start"
            disabled={disabled}
            control={
              <Checkbox
                checked={fetch}
                onChange={updateFetch}
                size="small"
                name="checkedB"
                color="primary"
              />
            }
            label="Email"
          />
        </div>
      </div>
    </Paper>
  );
}

class FormSubscription extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    subscription: PropTypes.object,

    api: PropTypes.func,
    subscription: PropTypes.object,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.subscription);
    }
  }

  async init(subscription) {
    const { api } = this.props;
    this.setState({ loading: true });
    const r = await api.getSubscriptionById(subscription.id);

    this.setState({
      loading: false,
      subscription: r.payload,
    });
  }

  async refresh() {
    const { api, subscription } = this.props;
    const r = await api.getSubscriptionById(subscription.id);
    this.setState({
      subscription: r.payload,
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this subscription, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { close, refresh, subscription, api } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await api.deleteSubscription(subscription.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Subscription has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { subscription, refresh, close, api } = this.props;

    const { name } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (subscription) {
      resp = await api.updateSubscription(subscription.id, {
        name,
      });
    } else {
      resp = await api.createSubscription({
        name,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: subscription
          ? "Subscription has been updated."
          : "Subscription has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  setEnabled(enabled) {
    const { api, refresh, email, token } = this.props;

    const { reasonID, explanation, subscription } = this.state;

    api
      .updateSubscription(subscription.id, {
        enabled,
        reasonID,
        explanation,
        email: subscription.email,
        token: subscription.token,
      })
      .then(this.refresh.bind(this));
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      close,
      api,
      rules,
      eventReasons,
    } = this.props;

    const { subscription, loading } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth
        classes={{
          paper: classes.paper,
        }}
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ maxHeight: 800, overflow: "auto" }}
                >
                  {subscription?.enabled ? (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => {
                        this.setEnabled(false);
                      }}
                    >
                      <i
                        class="fad fa-bell-slash"
                        style={{ paddingRight: 10 }}
                      ></i>{" "}
                      Unsubscribe All
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setEnabled(true);
                      }}
                      className={classes.submit}
                    >
                      <i class="fad fa-bell" style={{ paddingRight: 10 }}></i>{" "}
                      Enable
                    </Button>
                  )}
                  <br />
                  {rules?.map((r) => (
                    <div
                      style={{
                        flex: 1,
                        width: "100%",
                      }}
                      key={r.id}
                    >
                      <Rule
                        subRule={subscription?.rules?.find(
                          (s) => s.id === r.id
                        )}
                        disabled={!subscription?.enabled}
                        email={subscription?.email}
                        token={subscription?.token}
                        classes={classes}
                        rule={r}
                        api={api}
                        refresh={this.refresh.bind(this)}
                      />
                    </div>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ maxHeight: 800, overflow: "auto" }}
                >
                  <Typography variant="h6" display="block">
                    Events
                  </Typography>
                  <Typography variant="caption" display="block">
                    token: <CopyLabel label={subscription?.token} />
                  </Typography>
                  <Typography variant="caption" display="block">
                    email: <CopyLabel label={subscription?.email} />
                  </Typography>
                  <Divider />
                  {subscription?.events
                    ?.slice(0)
                    .reverse()
                    .map((e) => (
                      <div>
                        <Typography variant="caption">
                          {moment(e.createdAt).format("YYYY/MM/DD")}:{" "}
                          <b>{e.subscribed ? "Subscribed" : "Unsubscribed"}</b>{" "}
                          <b>
                            {!e.ruleID ? (
                              <span>
                                from
                                <span> all rules</span>
                              </span>
                            ) : (
                              <span>
                                from {e.explanation} -
                                <span>
                                  {` ${
                                    rules.find((r) => r.id === e.ruleID)
                                      ?.name ?? ""
                                  }`}
                                </span>
                              </span>
                            )}
                            {e.reasonID ? (
                              <div style={{ paddingLeft: 75 }}>
                                <span style={{ color: "#f44336" }}>
                                  {eventReasons.find(
                                    (er) => er.id === e.reasonID
                                  )?.name ?? "Unkown"}{" "}
                                  {e.explanation || ""}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </b>
                        </Typography>
                      </div>
                    ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormSubscription));
