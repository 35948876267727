import { TAGS } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import TagsPage from "./container/TagsPage";

export default function getRoute(userAuth) {
  const { account, postal } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [postal.name]: [postal.roles.user],
  };
  return {
    path: TAGS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: TagsPage,
  };
}
