import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperEmailPage from "../component/WrapperEmailPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getEmailById: (...args) => dispatch(Actions.getEmailById(...args)),
  forwardEmail: (...args) => dispatch(Actions.forwardEmail(...args)),
  getTrails: (...args) => dispatch(Actions.getTrails(...args)),
});
class EmailPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getEmailById: PropTypes.func,
    forwardEmail: PropTypes.func,
    getTrails: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      loading: true,
      trails: [
        {
          id: 1,
          emailID: 377,
          token: "dgjgqhr",
          remoteIP: "101.54.128.87",
          userAgent: "Chrome",
          createdAt: moment.utc().format(),
        },
      ],
      id: urlParams.id,
    };
    this.refresh();
  }

  async refresh() {
    const { getEmailById, getTrails } = this.props;
    const { id } = this.state;
    const r = await Promise.all([
      getEmailById(id),
      getTrails([
        {
          name: "emailID",
          comparison: "eq",
          value: Number(id),
        },
      ]),
    ]);

    if (r[0].success) {
      this.setState({
        email: r[0].payload,
        trails: r[1].payload,
        loading: false,
      });
    }
  }

  render() {
    const { location, history, forwardEmail } = this.props;

    const { urlParams, email, loading, trails } = this.state;

    return (
      <WrapperEmailPage
        history={history}
        location={location}
        urlParams={urlParams}
        forwardEmail={forwardEmail}
        trails={trails}
        loading={loading}
        email={email}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailPage);
