import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import UAParser from "ua-parser-js";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ForwardIcon from "@material-ui/icons/Forward";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Visibility from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import AttachFile from "@material-ui/icons/AttachFile";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

// custom
import Page from "components/Page";

// hooks
import CopyLabel from "hooks/CopyLabel";

// routes
import { EMAILS } from "constants/routes";
import {
  REACT_APP_API,
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config";

// styles
import styles from "./styles";

function SimpleDialog({
  open,
  close,
  NotificationCenter,
  forwardEmail,
  email,
}) {
  const [to, setTo] = React.useState("");
  const [cc, setCc] = React.useState("");
  const [bcc, setBcc] = React.useState("");
  const [subject, setSubject] = React.useState("");

  const send = async () => {
    close();
    let replaceMap = {};

    const recipients = [];
    let _tmp = to.split(",");
    _tmp.forEach((e) =>
      recipients.push({
        emailAddress: e.trim(),
      })
    );

    _tmp = cc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          cc: true,
        })
      );
    }

    _tmp = bcc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          bcc: true,
        })
      );
    }

    forwardEmail(email.id, {
      recipients,
      subject,
    }).then((r) => {
      if (r.success) {
        NotificationCenter.sweetAlert({
          title: "Email Forwarded",
          timestamp: new Date().getTime(),
          success: true,
        });
        setTimeout(NotificationCenter.hide, 1500);
      }
    });
  };

  return (
    <Dialog onClose={close} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent>
        <Typography variant="h6">Send Email</Typography>
        <Typography variant="caption" color="textSecondary">
          Multiple Recipients: Use the comma separator between email addresses.
        </Typography>
        <Grid container spacing={1} style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="To:"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Cc:"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Bcc:"
              value={bcc}
              onChange={(e) => setBcc(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={send} color="primary" autoFocus variant="contained">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class WrapperEmailPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    email: PropTypes.object,
    loading: PropTypes.bool,
    forwardEmail: PropTypes.func,
    trails: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    forwardOpen: false,
  };

  render() {
    const {
      email,
      loading,
      history,
      forwardEmail,
      trails,
      classes,
    } = this.props;
    const { forwardOpen, anchorEl } = this.state;
    const { NotificationCenter } = this.context;

    const to = [];
    const cc = [];
    const bcc = [];

    email &&
      email.recipients &&
      email.recipients.forEach((e) => {
        if (e.cc) {
          cc.push(e.emailAddress);
        } else if (e.bcc) {
          bcc.push(e.emailAddress);
        } else {
          to.push(e.emailAddress);
        }
      });

    const parser = UAParser();

    return (
      <Page
        helmet={"Email Preview"}
        loading={loading}
        noPadding
        header={
          email ? (
            <div style={{ padding: 16 }}>
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <Chip
                    label="Back"
                    size="small"
                    variant="outlined"
                    onClick={() => history.push(EMAILS)}
                    icon={<KeyboardBackspaceIcon />}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Organization #{email.organizationID}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        |
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Font {email.fontBundleID}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        |
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {email.configurationCode}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        |
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {email.sent
                          ? `✅ Sucessfully Sent ${
                              email.failCount
                                ? `(${email.failCount} Attempts)`
                                : ""
                            }`
                          : `⚠️ Message Unsent ${
                              email.failCount
                                ? `{${email.failCount} Attempts)`
                                : ""
                            }`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">|</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {moment(email.creationDate).format("lll")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Badge badgeContent={trails.length} color="primary">
                        <Chip
                          label="Views"
                          size="small"
                          variant="outlined"
                          onClick={(e) =>
                            this.setState({ anchorEl: e.currentTarget })
                          }
                          icon={<Visibility />}
                        />
                      </Badge>
                      <Popover
                        id={Boolean(anchorEl) ? "simple-popper" : undefined}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        onClose={() => this.setState({ anchorEl: null })}
                      >
                        <Paper
                          style={{
                            padding: 20,
                            maxHeight: 300,
                            overflow: "scroll",
                            width: 360,
                          }}
                        >
                          <Grid container spacing={1}>
                            {trails.length ? (
                              <>
                                <Grid item xs={6}>
                                  <Paper className={classes.paper}>
                                    <Typography variant="h6" color="inherit">
                                      Total View
                                    </Typography>
                                    <Typography color="inherit">
                                      {trails.length}
                                    </Typography>
                                  </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                  <Paper className={classes.paper}>
                                    <Typography variant="h6" color="inherit">
                                      Last seen
                                    </Typography>
                                    <Typography color="inherit">
                                      {moment(
                                        trails[trails.length - 1].createdAt
                                      ).fromNow()}
                                    </Typography>
                                  </Paper>
                                </Grid>
                              </>
                            ) : (
                              <Typography color="inherit">
                                Email not seen yet
                              </Typography>
                            )}
                            {trails &&
                              trails.map((t, i) => {
                                return (
                                  <Grid item xs={12}>
                                    <Chip label={t.remoteIP} size="small" />
                                    <Typography
                                      display="block"
                                      variant="caption"
                                    >
                                      {moment(t.createdAt).format("lll")}
                                    </Typography>
                                    <Typography
                                      display="block"
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {t.userAgent}
                                    </Typography>
                                    {Number(i) < trails.length - 1 ? (
                                      <Divider />
                                    ) : (
                                      []
                                    )}
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Paper>
                      </Popover>
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Forward"
                        size="small"
                        variant="outlined"
                        onClick={() => this.setState({ forwardOpen: true })}
                        icon={<ForwardIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item style={{ width: 50 }}>
                  <Typography variant="caption" color="textSecondary">
                    From:
                  </Typography>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="caption">
                    <b>{email.sentFromName}</b>(
                    <CopyLabel label={email.sentFromEmail} />)
                  </Typography>
                </Grid>
              </Grid>
              {to.length ? (
                <Grid container spacing={2}>
                  <Grid item style={{ width: 50 }}>
                    <Typography variant="caption" color="textSecondary">
                      To:
                    </Typography>
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="caption">
                      {to.map((t, i) => (
                        <React.Fragment>
                          <CopyLabel label={t} />
                          {Number(i) < to.length - 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}
              {cc.length ? (
                <Grid container spacing={2}>
                  <Grid item style={{ width: 50 }}>
                    <Typography variant="caption" color="textSecondary">
                      Cc:
                    </Typography>
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="caption">
                      {cc.map((t, i) => (
                        <React.Fragment>
                          <CopyLabel label={t} />
                          {Number(i) < cc.length - 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}
              {bcc.length ? (
                <Grid container spacing={2}>
                  <Grid item style={{ width: 50 }}>
                    <Typography variant="caption" color="textSecondary">
                      Bcc:
                    </Typography>
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="caption">
                      {bcc.map((t, i) => (
                        <React.Fragment>
                          <CopyLabel label={t} />
                          {Number(i) < bcc.length - 1 ? ", " : ""}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                []
              )}
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{ width: 50 }}>
                  <Typography variant="caption" color="textSecondary">
                    Subject:
                  </Typography>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="caption">
                    <b>{email.subject}</b>
                  </Typography>
                </Grid>
              </Grid>
              {email.attachments && email.attachments.length ? (
                <Grid container spacing={1} style={{ marginTop: 16 }}>
                  {email.attachments.map((f, i) => (
                    <Grid item>
                      <Chip
                        label={f.name}
                        onClick={() => window.open(f.url, "_blank")}
                        avatar={<AttachFile />}
                        variant="outlined"
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                []
              )}
            </div>
          ) : (
            []
          )
        }
      >
        {email ? (
          <iframe
            src={`${REACT_APP_API}${REACT_APP_API_PREFIX}/${REACT_APP_POSTAL_SERVICE_NAME}/emails/${email.id}/HTML`}
            style={{ flex: 1, border: "none" }}
          ></iframe>
        ) : (
          []
        )}
        <SimpleDialog
          open={forwardOpen}
          close={() => this.setState({ forwardOpen: false })}
          NotificationCenter={NotificationCenter}
          forwardEmail={forwardEmail}
          email={email}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEmailPage);
