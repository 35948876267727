import { GET_EMAILS } from "constants/emails";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_EMAILS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
