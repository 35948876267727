import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// styles
import styles from "./styles";

class Widget extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getValue: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    color: PropTypes.string,
    range: PropTypes.number,
    icon: PropTypes.node,
  };

  constructor(...args) {
    super(...args);
    const { range } = this.props;
    this.state = {
      range: range || 7,
    };
  }

  render() {
    const { getValue, icon, title, subtitle, color } = this.props;
    const { range } = this.state;

    return (
      <Paper className="card" style={{ color, height: "calc(100% - 90px)" }}>
        <Paper className="cardHeader" style={{ background: color }}>
          {icon}
        </Paper>
        <Grid container justify="flex-end">
          <Grid item>
            <Typography variant="h6" color="inherit">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h3">{getValue(range)}</Typography>
            <Typography color="textSecondary">{subtitle}</Typography>
            <br />
            {/* <Grid container spacing={1} style={{ color }}>
              <Grid item>
                <Typography color={range === 7 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ range: 7 })}>
                  Last Week
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={range === 30 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ range: 30 })}>
                  Last Month
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={range === 183 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ range: 183 })}>
                  Last 6 Months
                </Typography>
              </Grid>
              <Grid item>
                <Typography color={range === 365 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ range: 365 })}>
                  Last Year
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Widget);
