export default (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  revert: {
    color: "white",
    fontSize: 10,
    padding: "2px 6px",
    background: "rgba(155,155,155)",
    borderRadius: 20,
    cursor: "pointer",
  },
});
