import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperCourierPage from "../component/WrapperCourierPage";

const mapStateToProps = (state) => ({
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  getCouriers: () => dispatch(Actions.getCouriers()),
  getCourierById: (...args) => dispatch(Actions.getCourierById(...args)),
  createCourier: (...args) => dispatch(Actions.createCourier(...args)),
  deleteCourier: (...args) => dispatch(Actions.deleteCourier(...args)),
  updateCourier: (...args) => dispatch(Actions.updateCourier(...args)),

  getCourierListById: (...args) =>
    dispatch(Actions.getCourierListById(...args)),
  createCourierList: (...args) => dispatch(Actions.createCourierList(...args)),
  deleteCourierList: (...args) => dispatch(Actions.deleteCourierList(...args)),
  updateCourierList: (...args) => dispatch(Actions.updateCourierList(...args)),

  getCourierItemById: (...args) =>
    dispatch(Actions.getCourierItemById(...args)),
  createCourierItem: (...args) => dispatch(Actions.createCourierItem(...args)),
  deleteCourierItem: (...args) => dispatch(Actions.deleteCourierItem(...args)),
  updateCourierItem: (...args) => dispatch(Actions.updateCourierItem(...args)),
  courierReplace: (...args) => dispatch(Actions.courierReplace(...args)),
  emailSend: (...args) => dispatch(Actions.emailSend(...args)),
  emailHTML: (...args) => dispatch(Actions.emailHTML(...args)),

  createEmail: (...args) => dispatch(Actions.createEmail(...args)),

  getConfigurations: (...args) => dispatch(Actions.getConfigurations(...args)),
});

class CouriersPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    getCouriers: PropTypes.func,
    getCourierById: PropTypes.func,
    createCourier: PropTypes.func,
    deleteCourier: PropTypes.func,
    updateCourier: PropTypes.func,
    courierReplace: PropTypes.func,
    emailSend: PropTypes.func,
    emailHTML: PropTypes.func,

    getCourierListById: PropTypes.func,
    createCourierList: PropTypes.func,
    deleteCourierList: PropTypes.func,
    updateCourierList: PropTypes.func,

    getCourierItemById: PropTypes.func,
    createCourierItem: PropTypes.func,
    deleteCourierItem: PropTypes.func,
    updateCourierItem: PropTypes.func,

    createEmail: PropTypes.func,

    getConfigurations: PropTypes.func,
    configurations: PropTypes.array,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location, getConfigurations } = this.props;
    getConfigurations();
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      id: urlParams.id,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCourierById } = this.props;
    const { subscription } = this.state;
    const { WebsocketCenter } = this.context;
    const { id } = this.state;
    const resp = await getCourierById(id);
    if (resp.success) {
      const courier = resp.payload;
      if (subscription === undefined) {
        const s = WebsocketCenter.subscribe(
          `postal:${courier.uid}`,
          this.wsCallback.bind(this)
        );
        this.setState({ subscription: s });
      }

      this.setState({
        loading: false,
        courier,
        rKey: new Date().getTime(),
      });
    }
  }

  async wsCallback(message) {
    const { rKey } = this.state;
    const now = new Date().getTime();
    this.refresh();
  }

  refreshWrapper = (apiCall) => async (...args) => {
    await apiCall(...args);
    this.refresh();
    this.setState({
      rKey: new Date().getTime(),
    });
  };

  render() {
    const {
      getCourierById,
      createCourier,
      deleteCourier,
      updateCourier,
      getCourierListById,
      createCourierList,
      deleteCourierList,
      updateCourierList,
      getCourierItemById,
      createCourierItem,
      deleteCourierItem,
      updateCourierItem,
      courierReplace,
      createEmail,
      history,
      configurations,
      emailSend,
      emailHTML,
    } = this.props;

    const api = {
      getCourierById,
      createCourier,
      deleteCourier,
      updateCourier: this.refreshWrapper(updateCourier),
      getCourierListById,
      createCourierList: this.refreshWrapper(createCourierList),
      deleteCourierList: this.refreshWrapper(deleteCourierList),
      updateCourierList: this.refreshWrapper(updateCourierList),
      getCourierItemById,
      createCourierItem: this.refreshWrapper(createCourierItem),
      deleteCourierItem: this.refreshWrapper(deleteCourierItem),
      updateCourierItem: this.refreshWrapper(updateCourierItem),
      courierReplace,
      createEmail,
      emailSend,
      emailHTML,
    };

    const { urlParams, loading, courier, rKey } = this.state;

    return (
      <WrapperCourierPage
        loading={loading}
        courier={courier}
        history={history}
        configurations={configurations}
        rKey={rKey}
        api={api}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CouriersPage);
