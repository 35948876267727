import { GET, PUT, DELETE, POST } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const courierItems = `${REACT_APP_API_PREFIX}/${service}/courierItems`;

export function getCourierItems(filters) {
  const url = `${courierItems}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCourierItemById(id) {
  const url = `${courierItems}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCourierItem(id, body) {
  const url = `${courierItems}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCourierItem(id) {
  const url = `${courierItems}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCourierItem(body) {
  const url = `${courierItems}`;
  return {
    method: POST,
    url,
    body,
  };
}
