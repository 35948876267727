import { networkAction } from "helpers/network/networkAction";

import {
  UPLOAD,
  GET_UPLOADS,
  GET_UPLOAD_BY_ID,
  DELETE_UPLOAD,
  UPDATE_UPLOAD,
  CREATE_UPLOAD,
} from "constants/uploads";

import * as Api from "api";

export const upload = (file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD, Api.upload, [file]);

export const getUploads = () => async (dispatch) =>
  networkAction(dispatch, GET_UPLOADS, Api.getUploads, []);

export const getUploadById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_UPLOAD_BY_ID, Api.getUploadById, [id]);

export const deleteUpload = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_UPLOAD, Api.deleteUpload, [id]);

export const createUpload = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_UPLOAD, Api.createUpload, [body]);

export const updateUpload = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_UPLOAD, Api.updateUpload, [id, body]);
