import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "subs";
const eventReasons = `${REACT_APP_API_PREFIX}/${service}/eventReasons`;

export function getEventReasons(filters) {
  const url = `${eventReasons}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getEventReasonById(id) {
  const url = `${eventReasons}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateEventReason(id, body) {
  const url = `${eventReasons}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEventReason(id) {
  const url = `${eventReasons}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEventReason(body) {
  const url = `${eventReasons}`;
  return {
    method: POST,
    url,
    body,
  };
}
