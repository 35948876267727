import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import { REACT_APP_API_PREFIX } from "config/env";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class EmailsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    emails: PropTypes.array,
    configurations: PropTypes.array,
    getEmails: PropTypes.func,
    onSelect: PropTypes.func,
  };

  onSelect(selectedEmail) {
    const { onSelect } = this.props;
    this.setState({ selectedEmail });
    if (onSelect) {
      onSelect(selectedEmail);
    }
  }

  render() {
    const { emails, history, getEmails, configurations } = this.props;

    console.log(configurations);

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        refresh={getEmails}
        keyword="emails"
        metaDataEndpoint={`/${REACT_APP_API_PREFIX}/postal/emails/searchMetadata`}
        metaDataEndpoint={`${REACT_APP_API_PREFIX}/postal/emails/searchMetadata`}
        metaDataOveride={{
          configurationID: {
            type: "autocomplete",
            data: configurations,
            valueKey: "id",
            labelKey: "label",
          },
        }}
      >
        <Table
          data={emails}
          meta={[
            {
              path: "sent",
              render: (t, d) => (!d.sent ? "⚠️" : "✔️"),
            },
            {
              path: "recipients",
              title: "To",
              numeric: false,
              render: (t, d) => (
                <Grid container spacing={1}>
                  {d &&
                    d.recipients &&
                    d.recipients.slice(0, 1).map((r) => (
                      <Grid item>
                        <Chip label={r.emailAddress} size="mini" />
                      </Grid>
                    ))}
                  {d && d.recipients && d.recipients.length > 1 ? (
                    <Tooltip
                      title={`${d.recipients
                        .slice(1, d.recipients.length - 1)
                        .map((r) => ` ${r.emailAddress}`)}`}
                    >
                      <Grid item>
                        <Chip
                          label={`...${d.recipients.length - 1} more`}
                          size="mini"
                          variant="outlined"
                          style={{ cursor: "pointer" }}
                        />
                      </Grid>
                    </Tooltip>
                  ) : (
                    []
                  )}
                </Grid>
              ),
            },
            {
              path: "subject",
              title: "Subject",
              numeric: false,
            },
            {
              path: "sentFromEmail",
              title: "Sent From Email",
              numeric: false,
            },
            {
              path: "sentFromName",
              title: "Sent From Name",
              numeric: false,
            },
            {
              path: "createdAt",
              title: "Date",
              numeric: false,
              transform: (t) => moment(t).format("lll"),
            },
          ]}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(EmailsList);
