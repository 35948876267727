import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TwitterPicker } from "react-color";
// styles
import styles from "./styles";

class FormSearchPreset extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    searchPreset: PropTypes.object,
    filters: PropTypes.string,
    keyword: PropTypes.string,

    createSearchPreset: PropTypes.func,
    updateSearchPreset: PropTypes.func,
    deleteSearchPreset: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      global: false,
      name: "",
      color: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.searchPreset);
    }
  }

  init(searchPreset) {
    this.setState({
      loading: false,
      color: searchPreset ? searchPreset.color : "",
      gl: searchPreset ? searchPreset.gl : false,
      name: searchPreset ? searchPreset.name : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const value = event.target.checked;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this searchPreset, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: () => console.log("Callback"),
        },
      }
    );
  }

  async save() {
    const {
      updateSearchPreset,
      createSearchPreset,
      searchPreset,
      filters,
      keyword,
      refresh,
      close,
    } = this.props;

    const { name, color, gl } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (searchPreset) {
      resp = await updateSearchPreset(searchPreset.id, {
        name,
        color,
        keyword,
        global: gl,
        filter: filters,
      });
    } else {
      resp = await createSearchPreset({
        name,
        color,
        global: gl,
        keyword,
        filter: filters,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: searchPreset
          ? "SearchPreset has been updated."
          : "SearchPreset has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      close();
      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, searchPreset, close } = this.props;

    const { name, color, loading, gl } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {searchPreset
                ? `${searchPreset.name}`
                : "Let's Create a New Search Preset"}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item>
                  <div style={{ marginTop: 20 }}>
                    <TwitterPicker
                      color={color}
                      onChangeComplete={(c) => this.setState({ color: c.hex })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={gl}
                        onChange={this.handleCheckboxChange("gl")}
                      />
                    }
                    label="Make it Global"
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                  >
                    If set, all the team will see this preset.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {searchPreset ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />{" "}
                {searchPreset ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormSearchPreset));
