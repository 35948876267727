import React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// helpers
import { copyTextToClipboard } from "helpers";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CopyLabel({ label }) {
  const [hover, setHover] = React.useState(false);
  const [click, setClick] = React.useState(false);

  return (
    <Tooltip title={click ? "Copied!" : "Click to Copy"}>
      <span
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          setClick(true);
          copyTextToClipboard(label);
        }}
        style={{
          background: hover ? "rgba(33,150,243, 0.1)" : "rgba(33,150,243, 0)",
          border: hover
            ? "solid 1px rgba(33,150,243, 0.3)"
            : "solid 1px rgba(33,150,243, 0)",
          cursor: "pointer",
        }}
      >
        {label}
      </span>
    </Tooltip>
  );
}
