import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormTemplate extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    template: PropTypes.object,
    configurations: PropTypes.array,

    createTemplate: PropTypes.func,
    updateTemplate: PropTypes.func,
    deleteTemplate: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      code: "",
      sentFromName: "",
      sentFromEmail: "",
      configurationID: 0,
      label: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.template);
    }
  }

  init(template) {
    this.setState({
      loading: false,
      name: template ? template.name : "",
      code: template ? template.code : "",
      label: template ? template.label : "",
      configurationID: template ? template.configurationID : "",
      sentFromName: template ? template.sentFromName : "",
      sentFromEmail: template ? template.sentFromEmail : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle:
          "If you delete this template, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteTemplate, close, refresh, template } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteTemplate(template.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Template has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateTemplate,
      createTemplate,
      template,
      refresh,
      close,
    } = this.props;

    const {
      name,
      code,
      label,
      sentFromName,
      configurationID,
      sentFromEmail,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (template) {
      resp = await updateTemplate(template.id, {
        name,
        code,
        configurationID,
        label,
        sentFromName,
        sentFromEmail,
      });
    } else {
      resp = await createTemplate({
        name,
        code,
        label,
        configurationID,
        sentFromName,
        sentFromEmail,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: template
          ? "Template has been updated."
          : "Template has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      template,
      close,
      configurations,
    } = this.props;

    const {
      name,
      code,
      sentFromName,
      sentFromEmail,
      configurationID,
      label,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {template ? `${template.name}` : "Let's Create a New Template"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {template
                  ? "To update the template, fill the form below :"
                  : "To create a new template, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="label"
                    label="Label"
                    className={classes.textField}
                    value={label}
                    onChange={this.handleChange("label")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="code"
                    label="Code"
                    className={classes.textField}
                    value={code}
                    onChange={this.handleChange("code")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="sentFromName"
                    label="Sent From Name"
                    className={classes.textField}
                    value={sentFromName}
                    onChange={this.handleChange("sentFromName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="sentFromEmail"
                    label="Sent From Email"
                    className={classes.textField}
                    value={sentFromEmail}
                    onChange={this.handleChange("sentFromEmail")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="configurationID">Configuration</InputLabel>
                    <Select
                      id="configurationID"
                      value={configurationID}
                      onChange={this.handleChange("configurationID")}
                    >
                      {configurations &&
                        configurations.map((c) => (
                          <MenuItem value={c.id} key={c.id}>
                            {c.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {template ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />{" "}
                {template ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormTemplate));
