import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "subs";
const subscriptions = `${REACT_APP_API_PREFIX}/${service}/subscriptions`;

export function getSubscriptions(filters) {
  const url = `${subscriptions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getSubscriptionById(id) {
  const url = `${subscriptions}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSubscription(id, body) {
  const url = `${subscriptions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSubscription(id) {
  const url = `${subscriptions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSubscription(body) {
  const url = `${subscriptions}`;
  return {
    method: POST,
    url,
    body,
  };
}
