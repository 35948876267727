import * as Api from "api";
import {
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_BY_ID,
  UPDATE_SUBSCRIPTION,
} from "constants/subscriptions";
import { networkAction } from "helpers/network/networkAction";

export const getSubscriptions = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_SUBSCRIPTIONS, Api.getSubscriptions, [filters]);

export const getSubscriptionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUBSCRIPTION_BY_ID, Api.getSubscriptionById, [
    id,
  ]);

export const deleteSubscription = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUBSCRIPTION, Api.deleteSubscription, [id]);

export const createSubscription = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUBSCRIPTION, Api.createSubscription, [body]);

export const updateSubscription = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUBSCRIPTION, Api.updateSubscription, [
    id,
    body,
  ]);
