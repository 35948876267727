import React, { Component } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// components
import TagsList from "components/TagsList";
import Page from "components/Page";
import FormTag from "components/FormTag";

// styles
import styles from "./styles";

class WrapperTagsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //tags
    tags: PropTypes.array,
    getTagById: PropTypes.func,
    createTag: PropTypes.func,
    deleteTag: PropTypes.func,
    updateTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedTag: undefined,
      formTagOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { tags } = this.props;

    switch (index) {
      case 0:
        return (
          <TagsList
            tags={tags}
            onSelect={(selectedTag) =>
              this.setState({
                selectedTag,
                formTagOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createTag,
      deleteTag,
      updateTag,
      refresh,
    } = this.props;

    const { index, selectedTag, formTagOpen } = this.state;

    return (
      <Page
        helmet={"Tags"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formTagOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Tags"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormTag
          open={formTagOpen}
          tag={selectedTag}
          close={() => {
            this.setState({
              formTagOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedTag: undefined,
                }),
              200
            );
          }}
          createTag={createTag}
          deleteTag={deleteTag}
          updateTag={updateTag}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTagsPage);
