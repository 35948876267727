import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class TagsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    tags: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedTag) {
    const { onSelect } = this.props;
    this.setState({ selectedTag });
    if (onSelect) {
      onSelect(selectedTag);
    }
  }

  render() {
    const { tags } = this.props;

    return (
      <Table
        data={tags}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
            render: (t, d) => (
              <Chip
                size="mini"
                label={t}
                style={{ color: "white", background: d.color }}
              />
            ),
          },
        ]}
        title={"Tags"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(TagsList);
