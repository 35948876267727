import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Chart from "react-apexcharts";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./styles";

class DatetimeGraph extends Component {
  static propTypes = {
    data: PropTypes.array,
    dataKey: PropTypes.string,
    dateKey: PropTypes.string,
    height: PropTypes.number,
    color: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    const { data, dateKey } = this.props;
    this.state = {
      graph: moment(data[0][dateKey]).diff(
        moment(data[data.length - 1][dateKey]),
        "days"
      ),
    };
  }

  getGraphConfig(range) {
    const { data, dateKey, height, color } = this.props;
    const graphData = [];
    const colors = [];

    const currentDay = moment(data[0][dateKey]);
    for (let index = 0; index < range; index++) {
      graphData.push([currentDay.toDate().getTime(), 0]);
      currentDay.subtract(1, "d");
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const now = moment(data[0][dateKey]); // todays date
        const end = moment(element[dateKey]); // another date
        const duration = moment.duration(now.diff(end));
        const days = Math.round(duration.asDays());
        if (graphData[days]) {
          graphData[days][1] += 1;
          colors.push("#777777");
        }
      }
    }

    const options = {
      chart: {
        height: height || 200,
      },
      colors: [color || "#FF7F00"],
      stroke: {
        width: 0,
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
        type: "solid",
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: false,
        },
      },
      xaxis: {
        type: "datetime",
      },
    };
    const series = [
      {
        name: "Events",
        data: graphData,
      },
    ];

    const config = { options, series, type: "bar" };
    return config;
  }

  render() {
    const { height } = this.props;
    const { graph } = this.state;
    const config = this.getGraphConfig(graph);

    console.log(config);

    return (
      <div>
        <Chart
          options={config.options}
          series={config.series}
          type={config.type}
          height={height || 200}
        />
      </div>
    );
  }
}

export default withStyles(styles)(DatetimeGraph);
