import { networkAction } from "helpers/network/networkAction";

import {
  GET_CONFIGURATIONS,
  GET_CONFIGURATION_BY_ID,
  DELETE_CONFIGURATION,
  UPDATE_CONFIGURATION,
  CREATE_CONFIGURATION,
} from "constants/configurations";

import * as Api from "api";

export const getConfigurations = () => async (dispatch) =>
  networkAction(dispatch, GET_CONFIGURATIONS, Api.getConfigurations, []);

export const getConfigurationById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CONFIGURATION_BY_ID, Api.getConfigurationById, [
    id,
  ]);

export const deleteConfiguration = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CONFIGURATION, Api.deleteConfiguration, [id]);

export const createConfiguration = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CONFIGURATION, Api.createConfiguration, [
    body,
  ]);

export const updateConfiguration = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CONFIGURATION, Api.updateConfiguration, [
    id,
    body,
  ]);
