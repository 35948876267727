import { networkAction } from "helpers/network/networkAction";

import {
  GET_COURIER_LISTS,
  GET_COURIER_LIST_BY_ID,
  DELETE_COURIER_LIST,
  UPDATE_COURIER_LIST,
  CREATE_COURIER_LIST,
} from "constants/courierLists";

import * as Api from "api";

export const getCourierLists = () => async (dispatch) =>
  networkAction(dispatch, GET_COURIER_LISTS, Api.getCourierLists, []);

export const getCourierListById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COURIER_LIST_BY_ID, Api.getCourierListById, [id]);

export const deleteCourierList = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COURIER_LIST, Api.deleteCourierList, [id]);

export const createCourierList = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COURIER_LIST, Api.createCourierList, [body]);

export const updateCourierList = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COURIER_LIST, Api.updateCourierList, [
    id,
    body,
  ]);
