import { GET, PUT, DELETE, POST } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const emails = `${REACT_APP_API_PREFIX}/${service}/emails`;

export function getEmails(filters) {
  const url = `${emails}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getEmailDashboard() {
  const url = `${emails}/dashboard`;
  return {
    method: GET,
    url,
  };
}

export function getEmailById(id) {
  const url = `${emails}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function forwardEmail(id, body) {
  const url = `${emails}/${id}/forward`;
  return {
    method: PUT,
    body,
    url,
  };
}

export function updateEmail(id, body) {
  const url = `${emails}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEmail(id) {
  const url = `${emails}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEmail(body) {
  const url = `${emails}`;
  return {
    method: POST,
    url,
    body,
  };
}
