import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AttachFile from "@material-ui/icons/AttachFile";
import FlagIcon from "@material-ui/icons/Flag";
import Export from "@material-ui/icons/GetApp";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PrintIcon from "@material-ui/icons/Print";
import Send from "@material-ui/icons/Send";
import Config from "@material-ui/icons/SettingsApplications";
import Text from "@material-ui/icons/TextFormat";
import TouchAppIcon from "@material-ui/icons/TouchAppTwoTone";
import Rating from "@material-ui/lab/Rating";
import Lists from "components/Lists";
// components
import Page from "components/Page";
// config
import {
  REACT_APP_API,
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";
// helpers
import { copyTextToClipboard } from "helpers/url";
// Hooks
import Customizer from "hooks/Customizer";
import KeyValue from "hooks/KeyValue";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import fileDownload from "react-file-download";
import { Bar, Container, Section } from "react-simple-resizer";
import ReactToPrint from "react-to-print";
// styles
import styles from "./styles";

const priorityLabels = {
  3: "HIGH",
  2: "NORMAL",
  1: "LOW",
};

function PlainDialog(props) {
  const { close, selectedValue, open, courier, api } = props;
  const [plainText, setPlainText] = React.useState(courier.plainText);

  const save = async () => {
    await api.updateCourier(courier.id, { plainText }).then(close);
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="simple-dialog-title"
      fullWidth
      maxWidth="md"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <div>
          <Typography variant="h6">Plain Text Version</Typography>
          <Typography variant="caption" color="textSecondary">
            Plain Text version improves the spam score.
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Plain Text version"
          value={plainText}
          variant="outlined"
          fullWidth
          multiline
          onChange={(e) => setPlainText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SimpleDialog({
  open,
  close,
  api,
  NotificationCenter,
  content,
  configurations,
  courier,
  UploadCenter,
  files,
  setFiles,
}) {
  let sc = "";
  if (configurations && configurations.length === 1) {
    sc = configurations[0];
  }

  const [to, setTo] = React.useState("");
  const [cc, setCc] = React.useState("");
  const [priority, setPriority] = React.useState(1);
  const [json, setJson] = React.useState(false);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [jsonContent, setJsonContent] = React.useState("");
  const [bcc, setBcc] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [keyValues, setKeyValues] = React.useState([]);
  const [html, setHTML] = React.useState("");
  const myRef = React.useRef(null);
  const refContainer = React.useRef();
  const [selectedConfiguration, setSelectedConfiguration] = React.useState(sc);

  const send = async () => {
    close();
    let replaceMap = {};

    for (const k in keyValues) {
      if (keyValues.hasOwnProperty(k)) {
        const item = keyValues[k];
        replaceMap[item.key] = item.value;
      }
    }

    const recipients = [];
    let _tmp = to.split(",");
    _tmp.forEach((e) =>
      recipients.push({
        emailAddress: e.trim(),
      })
    );

    _tmp = cc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          cc: true,
        })
      );
    }

    _tmp = bcc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          bcc: true,
        })
      );
    }

    const attachments = [];

    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        attachments.push({
          url: file.url,
        });
      }
    }

    api
      .emailSend(courier.id, {
        templateCode: selectedConfiguration.code,
        subject,
        attachments,
        priority: 4 - priority,
        replaceMap: json ? JSON.parse(jsonContent) : replaceMap,
        recipients,
      })
      .then((r) => {
        if (r.success) {
          NotificationCenter.sweetAlert({
            title: "Email Sent",
            timestamp: new Date().getTime(),
            success: true,
          });
          setTimeout(NotificationCenter.hide, 1500);
        }
      });
  };

  const preview = async () => {
    let replaceMap = {};
    for (const k in keyValues) {
      if (keyValues.hasOwnProperty(k)) {
        const item = keyValues[k];
        if (item) {
          replaceMap[item.key] = item.value;
        }
      }
    }

    const recipients = [];
    let _tmp = to.split(",");
    _tmp.forEach((e) =>
      recipients.push({
        emailAddress: e.trim(),
      })
    );

    _tmp = cc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          cc: true,
        })
      );
    }

    _tmp = bcc.split(",");
    if (_tmp && _tmp[0] !== "") {
      _tmp.forEach((e) =>
        recipients.push({
          emailAddress: e.trim(),
          bcc: true,
        })
      );
    }
    const r = await fetch(
      `${REACT_APP_API}${REACT_APP_API_PREFIX}/${REACT_APP_POSTAL_SERVICE_NAME}/couriers/${courier.id}/emailHTML`,
      {
        body: JSON.stringify({
          templateCode: selectedConfiguration.code,
          subject,
          replaceMap: json ? JSON.parse(jsonContent) : replaceMap,
          recipients,
        }),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    );
    r.text().then((p) => {
      setHTML(p);
      setPreviewOpen(true);
    });
  };

  return (
    <React.Fragment>
      <Dialog onClose={close} aria-labelledby="simple-dialog-title" open={open}>
        <DialogContent>
          <Typography variant="h6">Send Email</Typography>
          <Typography variant="caption" color="textSecondary">
            Multiple Recipients: Use the comma separator between email
            addresses.
          </Typography>
          <Grid container spacing={1} style={{ marginTop: 8 }}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>From:</InputLabel>
                <Select
                  error={!selectedConfiguration}
                  label="From:"
                  value={selectedConfiguration}
                  onChange={(e) => setSelectedConfiguration(e.target.value)}
                >
                  {configurations &&
                    configurations.map((c) => (
                      <MenuItem value={c}>
                        {c.sentFromEmail} ({c.label})
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="To:"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Cc:"
                value={cc}
                onChange={(e) => setCc(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Bcc:"
                value={bcc}
                onChange={(e) => setBcc(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="standard-basic"
                label="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Priority
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Rating
                  style={{
                    color: "#f44336",
                  }}
                  value={priority}
                  onChange={(event, newValue) => {
                    setPriority(newValue);
                  }}
                  defaultValue={1}
                  max={3}
                  icon={<FlagIcon fontSize="inherit" />}
                />
                <Typography
                  variant="body2"
                  style={{ textTransform: "capitalize" }}
                >
                  {priorityLabels[priority].toLowerCase()}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container soacing={1}>
                <Grid item>
                  <Typography variant="h6">Attachements</Typography>
                  <Typography variant="caption" color="textSecondary">
                    <Chip
                      label="Browse files..."
                      onClick={UploadCenter.openFileDialog}
                    />
                  </Typography>
                  <Grid container spacing={1} style={{ marginTop: 16 }}>
                    {files &&
                      files.map((f, i) => (
                        <Grid item>
                          <Chip
                            label={f.name}
                            avatar={<AttachFile />}
                            variant="outlined"
                            onDelete={() => {
                              files.splice(Number(i), 1);
                              setFiles(files);
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 8 }}>
              <Divider />
            </Grid>
            {courier && courier.replaceMap ? (
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant="h6">Replace placeholders</Typography>
                    <Typography variant="caption" color="textSecondary">
                      Chose the text for every placeholder.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          value=""
                          checked={json}
                          onChange={(e) => setJson(e.target.checked)}
                          inputProps={{ "aria-label": "JSON" }}
                        />
                      }
                      label="JSON"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6">Nothing to replace</Typography>
                <Typography variant="caption" color="textSecondary">
                  Make sure to add some placeholder using double brackets
                  (example: {"{{firstName}}"} )
                </Typography>
              </Grid>
            )}
            {!json &&
              courier &&
              courier.replaceMap &&
              Object.keys(courier.replaceMap).map((k, i) => (
                <Grid item xs={12}>
                  <KeyValue
                    index={i}
                    onChange={(v, index) => {
                      const _keyValues = [...keyValues];
                      _keyValues[index] = v;
                      setKeyValues(_keyValues);
                    }}
                    keyword={k}
                    size="mini"
                  />
                </Grid>
              ))}
            {json ? (
              <TextField
                placeholder="Json Object"
                multiline
                value={jsonContent}
                variant="outlined"
                fullWidth
                onChange={(e) => setJsonContent(e.target.value)}
              />
            ) : (
              []
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              {!selectedConfiguration ? (
                <Tooltip title="Select the sender address first">
                  <Button color="secondary" variant="outlined">
                    Preview
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={() => preview()}
                  color="secondary"
                  variant="outlined"
                >
                  Preview
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={close} color="primary">
                Cancel
              </Button>
              <Button
                onClick={send}
                color="primary"
                autoFocus
                variant="contained"
                disabled={!selectedConfiguration || !to}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog onClose={() => setPreviewOpen(false)} open={previewOpen}>
        <div>
          <div style={{ textAlign: "center", padding: 4 }}>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <Chip
                    label="Print"
                    variant="outlined"
                    avatar={
                      <Avatar style={{ background: "none" }}>
                        <PrintIcon />
                      </Avatar>
                    }
                  />
                );
              }}
              content={() => myRef.current}
            />
          </div>
          {/* <div ref={myRef}>Hello World</div> */}
          <div dangerouslySetInnerHTML={{ __html: html }} ref={myRef} />
        </div>
      </Dialog>
    </React.Fragment>
  );
}

class WrapperCouriersPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    // Couriers
    courier: PropTypes.object,
    configurations: PropTypes.array,
    rKey: PropTypes.number,
    api: PropTypes.object,
  };

  static contextTypes = {
    KeyboardEventCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { KeyboardEventCenter, UploadCenter } = this.context;
    this.targetRef = React.createRef();
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  state = {
    courierListID: undefined,
    courierItemID: undefined,
    reorder: true,
    plainOpen: false,
    sendOpen: false,
    selectedConfiguration: "",
    files: [],
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.configurations &&
      nextProps.configurations.length &&
      this.state.selectedConfiguration === ""
    ) {
      this.setState({ selectedConfiguration: nextProps.configurations[0] });
    }
  }

  async upload() {
    const { sendOpen } = this.state;
    const { UploadCenter, NotificationCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        if (!file.url) {
          UploadCenter.upload(file).then((r) => {
            const link = r.payload;
            file.url = link;
            this.setState({ files });
            if (!sendOpen) {
              copyTextToClipboard(link);
              NotificationCenter.stack({
                title: "Image Url saved on Clipboard",
                body: "Paste the URL wherever you need",
                timestamp: new Date().getTime(),
                info: true,
              });
              setTimeout(NotificationCenter.hide, 3000);
            }
          });
        }
      }
    }
  }

  async handleExport() {
    const { courier } = this.props;
    if (courier) {
      fileDownload(
        JSON.stringify(courier, null, "\t"),
        `${courier.name}_${moment().format("YYYY-MM-DD HH:mm:ss")}.json`
      );
    }
  }

  userDidDrop(f) {
    const { files } = this.state;

    const _files = files.concat(f);
    this.setState({
      files: _files,
    });
  }

  render() {
    const { loading, courier, api, configurations, rKey, history } = this.props;

    const { NotificationCenter, UploadCenter } = this.context;

    const {
      courierListID,
      courierItemID,
      selectedConfiguration,
      sendOpen,
      reorder,
      anchorEl,
      plainOpen,
      files,
    } = this.state;

    return (
      <Page
        helmet={"Courier"}
        loadingMessage={"Loading Courier"}
        loading={loading}
        header={
          <Grid container style={{ padding: 8 }} justify="space-between">
            <Grid item>
              <Chip
                label="Back"
                size="small"
                variant="outlined"
                onClick={() => history.goBack()}
                icon={<KeyboardBackspaceIcon />}
              />
            </Grid>
            <Grid item>
              <Chip
                label={`Plain Text`}
                icon={<Text />}
                style={{ marginRight: 8 }}
                size="small"
                onClick={(event) => {
                  this.setState({ plainOpen: true });
                }}
              />
              <Chip
                label={`Export`}
                icon={<Export />}
                style={{ marginRight: 8 }}
                size="small"
                onClick={this.handleExport.bind(this)}
              />
              <Tooltip title="Select the Template">
                <Chip
                  icon={<Config />}
                  label={`${
                    selectedConfiguration && selectedConfiguration.code
                  } ▾`}
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={(event) => {
                    this.setState({ anchorEl: event.currentTarget });
                  }}
                />
              </Tooltip>
              <Chip
                label={`Compose Email`}
                icon={<Send />}
                color="primary"
                size="small"
                onClick={(event) => {
                  this.setState({ sendOpen: true, files: [] });
                }}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={() => this.setState({ anchorEl: null })}
              >
                {configurations &&
                  configurations.map((t) => (
                    <MenuItem
                      onClick={() =>
                        this.setState({
                          selectedConfiguration: t,
                          anchorEl: null,
                        })
                      }
                    >
                      {t.code}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>
          </Grid>
        }
        noPadding
      >
        {courier ? (
          <Container
            style={{ flex: 1, maxHeight: "calc(100vh - 87px)" }}
            onActivate={() => console.log("onActivate")}
          >
            <Section
              minSize={100}
              style={{
                padding: 16,
                overflow: "scroll",
                background: selectedConfiguration.backgroundColor,
                position: "relative",
              }}
            >
              <iframe
                title="preview"
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                src={`${REACT_APP_API}${REACT_APP_API_PREFIX}/${REACT_APP_POSTAL_SERVICE_NAME}/couriers/${
                  courier.id
                }/HTML?template=${
                  selectedConfiguration && selectedConfiguration.code
                }&refreshKey=${rKey}`}
              />
            </Section>
            <Bar
              size={5}
              style={{
                background: "rgba(155,155,155,0.3)",
                cursor: "col-resize",
              }}
            />
            <Section
              minSize={100}
              style={{ padding: 16, overflow: "scroll" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  courierListID: undefined,
                  courierItemID: undefined,
                });
              }}
            >
              <Lists
                key={rKey}
                reorder={reorder}
                parentID={courier.id}
                lists={courier.content}
                onSelect={(cli, clii) =>
                  this.setState({ courierListID: cli, courierItemID: clii })
                }
                selected={{ courierListID, courierItemID }}
                api={api}
              />
            </Section>
            <Bar
              size={5}
              style={{
                background: "rgba(155,155,155,0.3)",
                cursor: "col-resize",
              }}
            />
            <Section
              defaultSize={320}
              style={{ padding: 16, overflow: "scroll", display: "flex" }}
            >
              {courierListID || courierItemID ? (
                <Customizer
                  selected={{ courierListID, courierItemID }}
                  saved={() => console.log("saved")}
                  NotificationCenter={NotificationCenter}
                  key={`${courierListID}-${courierItemID}`}
                  api={api}
                />
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ flex: 1 }}
                >
                  <Grid item style={{ textAlign: "center" }}>
                    <TouchAppIcon
                      style={{ color: "rgba(155,155,155,0.8)", fontSize: 60 }}
                    />
                    <br />
                    <Typography color="textSecondary">
                      Select a list or item
                      <br />
                      to customize in the builder.
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Section>
          </Container>
        ) : (
          []
        )}
        <SimpleDialog
          open={sendOpen}
          files={files}
          api={api}
          configurations={configurations}
          setFiles={(f) => this.setState({ files: f })}
          courier={courier}
          UploadCenter={UploadCenter}
          content={courier && courier.content}
          NotificationCenter={NotificationCenter}
          selectedConfiguration={selectedConfiguration}
          close={() => this.setState({ sendOpen: false })}
        />
        {courier ? (
          <PlainDialog
            open={plainOpen}
            api={api}
            courier={courier}
            close={() => this.setState({ plainOpen: false })}
          />
        ) : (
          []
        )}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCouriersPage);
