import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperConfigurationsPage from "../component/WrapperConfigurationsPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  getConfigurations: () => dispatch(Actions.getConfigurations()),
  getConfigurationById: (...args) =>
    dispatch(Actions.getConfigurationById(...args)),
  createConfiguration: (...args) =>
    dispatch(Actions.createConfiguration(...args)),
  deleteConfiguration: (...args) =>
    dispatch(Actions.deleteConfiguration(...args)),
  updateConfiguration: (...args) =>
    dispatch(Actions.updateConfiguration(...args)),
});
class ConfigurationsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    configurations: PropTypes.array,
    getConfigurations: PropTypes.func,
    getConfigurationById: PropTypes.func,
    createConfiguration: PropTypes.func,
    deleteConfiguration: PropTypes.func,
    updateConfiguration: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, configurations } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: configurations.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getConfigurations } = this.props;
    await Promise.all([getConfigurations()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      configurations,
      getConfigurationById,
      createConfiguration,
      deleteConfiguration,
      updateConfiguration,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperConfigurationsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        configurations={configurations}
        getConfigurationById={getConfigurationById}
        createConfiguration={createConfiguration}
        deleteConfiguration={deleteConfiguration}
        updateConfiguration={updateConfiguration}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationsPage);
