import React, { Component } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

// components
import EmailDashboard from "components/EmailDashboard";
import EmailsList from "components/EmailsList";
import Page from "components/Page";
import FormEmail from "components/FormEmail";

// config
import { EMAIL } from "constants/routes";

// styles
import styles from "./styles";

class WrapperEmailsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    dashboard: PropTypes.any,

    //emails
    emails: PropTypes.array,
    getEmails: PropTypes.func,
    getEmailById: PropTypes.func,
    createEmail: PropTypes.func,
    deleteEmail: PropTypes.func,
    updateEmail: PropTypes.func,
    configurations: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedEmail: undefined,
      formEmailOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { emails, history, getEmails, configurations } = this.props;

    switch (index) {
      case 0:
        return (
          <EmailsList
            history={history}
            emails={emails}
            getEmails={getEmails}
            configurations={configurations}
            // onSelect={(selectedEmail) => window.open(`${REACT_APP_API}/postal/emails/${selectedEmail.id}/HTML`, '_blank')}
            onSelect={(selectedEmail) =>
              history.push(`${EMAIL}?id=${selectedEmail.id}`)
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createEmail,
      deleteEmail,
      updateEmail,
      refresh,
      dashboard,
    } = this.props;

    const { index, selectedEmail, formEmailOpen } = this.state;

    return (
      <Page
        helmet={"Emails"}
        loadingMessage={"Loading Document"}
        loading={loading}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Emails"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <EmailDashboard dashboard={dashboard} />
        <div style={{ marginTop: 16 }}>{this.getStepContent()}</div>
        <FormEmail
          open={formEmailOpen}
          email={selectedEmail}
          close={() => {
            this.setState({
              formEmailOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedEmail: undefined,
                }),
              200
            );
          }}
          createEmail={createEmail}
          deleteEmail={deleteEmail}
          updateEmail={updateEmail}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEmailsPage);
