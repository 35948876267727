import React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function KeyValue(props) {
  const { onChange, onDelete, index } = props;
  const [key, setKey] = React.useState(props.keyword || "");
  const [value, setValue] = React.useState(props.value || "");
  const classes = useStyles(styles);

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputBase
              value={key}
              placeholder={"Key"}
              disabled
              onChange={(e) => {
                onChange(
                  {
                    key: e.target.value,
                    value,
                  },
                  index
                );
                setKey(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputBase
              value={value}
              placeholder={"Value"}
              onChange={(e) => {
                onChange(
                  {
                    key,
                    value: e.target.value,
                  },
                  index
                );
                setValue(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
