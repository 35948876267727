import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/AddCircleOutline";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";

// components
import CourierItem from "components/CourierItem";
import LongHover from "components/LongHover";

// styles
import styles from "./styles";

const SortableItem = SortableElement(({ courierItem, props }) => (
  <Grid item style={{ flex: 1 }}>
    <CourierItem courierItem={courierItem} {...props} />
  </Grid>
));

const SortableCourierItem = SortableContainer(({ items, props }) => (
  <Grid container>
    {items &&
      items.map((a, index) => (
        <SortableItem key={a.id} courierItem={a} index={index} props={props} />
      ))}
  </Grid>
));

class CourierItems extends Component {
  static propTypes = {
    classes: PropTypes.object,
    courierItems: PropTypes.object,
    api: PropTypes.object,
    courierListID: PropTypes.number,
    onSelect: PropTypes.func,
    parentID: PropTypes.number,
    selected: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { courierItems } = this.props;

    this.state = {
      courierItems,
      itemID: "",
      dialogOpen: false,
    };
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { api } = this.props;
    const { updateCourierItem } = api;
    const itemToUpdate = this.state.courierItems[oldIndex];
    updateCourierItem(itemToUpdate.id, { order: newIndex + 1 });
    this.setState(({ courierItems }) => ({
      courierItems: arrayMove(courierItems, oldIndex, newIndex),
    }));
  };

  shouldCancelStart = (e) => {
    if (
      !(
        e.target.id === "grab" ||
        (e.target.nearestViewportElement &&
          e.target.nearestViewportElement.id === "grab")
      )
    ) {
      return true; // Return true to cancel sorting
    }
  };

  render() {
    const {
      classes,
      onSelect,
      selected,
      api,
      courierListID,
      parentID,
    } = this.props;
    const { courierItems, anchorEl, itemID, dialogOpen } = this.state;

    return (
      <Grid container justify="space-between" alignItems="center">
        <Grid item style={{ flexGrow: 1 }}>
          <SortableCourierItem
            axis="x"
            items={courierItems || []}
            shouldCancelStart={this.shouldCancelStart.bind(this)}
            props={{
              classes,
              onSelect,
              selected,
              courierListID,
            }}
            onSortEnd={this.onSortEnd}
            key="0"
          />
        </Grid>
        <Grid item>
          <Tooltip title="Add a Row Item">
            <LongHover onLongHover={(aEl) => this.setState({ anchorEl: aEl })}>
              <IconButton
                size="small"
                onClick={() => {
                  api.createCourierItem({
                    parentID,
                  });
                }}
              >
                <Add style={{ fontSize: 18 }} />
              </IconButton>
            </LongHover>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
          >
            <MenuItem
              onClick={() => {
                api.createCourierItem({
                  parentID,
                });
              }}
            >
              New Item
            </MenuItem>
            <MenuItem onClick={() => this.setState({ dialogOpen: true })}>
              Duplicate From Existing Item
            </MenuItem>
          </Menu>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            What is the id of the existing item?
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              size="small"
              margin="dense"
              label="Item ID"
              value={itemID}
              onChange={(e) => this.setState({ itemID: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogOpen: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                api.getCourierItemById(Number(itemID)).then((r) => {
                  api.createCourierItem({
                    ...r.payload,
                    id: undefined,
                    order: undefined,
                    parentID,
                  });
                });
              }}
              color="primary"
            >
              Duplicate
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(CourierItems);
