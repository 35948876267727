import { GET, PUT, DELETE, POST } from "constants/methods";

import {
  REACT_APP_API_PREFIX,
  REACT_APP_POSTAL_SERVICE_NAME,
} from "config/env";

const service = REACT_APP_POSTAL_SERVICE_NAME;
const templates = `${REACT_APP_API_PREFIX}/${service}/templates`;

export function getTemplates(filters) {
  const url = `${templates}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getTemplateById(id) {
  const url = `${templates}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateTemplate(id, body) {
  const url = `${templates}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTemplate(id) {
  const url = `${templates}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTemplate(body) {
  const url = `${templates}`;
  return {
    method: POST,
    url,
    body,
  };
}
